import { Col, Collapse, Row, Table, Typography } from "antd";
import styled from "styled-components";

export const StyledCollapse = styled(Collapse)`
  background-color: white;
  margin-top: 1rem;
  width: inherit;

  .ant-tabs-nav-wrap {
    font-weight: 500;

    @media screen and (min-width: 750px) {
      justify-content: center;
    }
  }
  .ant-tabs-content-holder {
    width: 90%;
    margin: auto;
  }
`;
export const TableFooter = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 625px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 0.5rem;
  }
`;
export const StyledRow = styled(Row)`
  font-weight: 600;
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  font-size: 1.5rem !important;

  @media (max-width: 775px) {
    display: ${(props) => (props?.display === "block" ? "none" : "flex")};
  }
`;
export const StyleTitle = styled(Typography.Title)`
  margin: auto !important;
`;
export const BlockScriptTitle = styled.div`
  display: flex;
  justify-content: ${(props) => (props?.isOwner ? "space-between" : "center")};
`;
export const CheckBoxColumn = styled(Col)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledTable = styled(Table)`
  margin-top: 2rem;
  // Define a styled component for the Table
  .ant-table-thead tr th {
    padding: 20px 0px !important;
    background-color: #f0f2f5; /* Change the background color of table headers */
  }

  .ant-table-tbody > tr > td {
    padding: 15px !important;
    color: #727880 !important;
  }
  .ant-table-thead tr .ant-table-cell {
    background-color: #798478;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    padding: 15px !important;
    font-size: 1rem;
    @media (max-width: 1079px) {
      font-size: 0.8rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }
`;
const StyledSummarySearchInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: unset !important;
  }
`;
const StyledImg = styled.img`
  margin-left: 2rem;
  width: 25px;
  height: 25px;
`;
const StyledThemeCard = styled.div`
  margin-top: 2rem;
  border-radius: ${(props) =>
    `${props.theme.token.borderRadiusLG}px ${props.theme.token.borderRadiusLG}px`};
  background-color: ${(props) => props.theme.token.themeCardBackground};
  padding: 2rem 0rem;
`;
const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.token.textColor};
  margin-right: 1rem;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
  }
`;
