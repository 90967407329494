import React, { useEffect, useRef, useState } from "react";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { Col, Row, notification } from "antd";
import ThemeCard from "../../components/ThemeCard";
import { PdfDiv, StyledSpan, buttonTitles } from "../SummaryReport/style";
import ThemeCardTable from "../../components/ThemeCardTable";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import { useQuery } from "@tanstack/react-query";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import APICall from "../../APICall";
import {
  setSummaryDataFilter,
  setUserClearFilter,
} from "../../store/slice/globleSlice";
import { useDispatch, useSelector } from "react-redux";
import { showValue } from "../../components/CommanFuction/CommanFunction";
import { FilePdfOutlined } from "@ant-design/icons";
import EndPoints from "../../APICall/EndPoints";
import { useNavigate } from "react-router";
import { StyledButton } from "../../styles/commonStyle";
import ValanComponant from "../../components/ValanComponant/ValanComponant";

const BrokerSharingReport = () => {
  // state only for render page after getting valan data from child component because summary api call after getting valan data
  const [getValan, setGetValan] = useState(false);

  const valueRef = useRef({});
  const multipleValueRef = useRef({});
  const { params, setSearchParams } = useGetQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  //life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params?.userId : loginId,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginId });
    }
  }, [params]);

  // api call
  const getBrokerageSharingReport = async () => {
    let isAllow = filterUser?.includes(
      Number(params?.userId ? params.userId : params?.brokerId)
    );
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    // let url = `${EndPoints?.getBrokerSharingReport}?`;
    // for (let key in params) {
    //   url += `${key}=${params[key]}&`;
    // }
    let url =
      `${EndPoints?.getBrokerSharingReport}?` +
      Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    let response = await APICall("get", url);
    return response?.data?.data;
  };

  const {
    isLoading,
    error: brokerSharingError,
    data: brokerSharingData,
  } = useQuery({
    queryKey: ["brokerSharingReport", params],
    queryFn: getBrokerageSharingReport,
    enabled:
      Object.keys(params)?.length > 0 && valueRef?.current?.valan !== undefined,
  });

  if (brokerSharingError) {
    notification.error({ message: brokerSharingError?.message });
  }

  // function
  const handleSubmit = () => {
    let refValue = {
      userId: valueRef.current?.userId || loginId,
    };

    if (
      multipleValueRef.current?.broker &&
      !multipleValueRef.current?.customer
    ) {
      refValue.brokerId = multipleValueRef.current?.broker;
      delete refValue.userId;
    }

    if (valueRef.current?.valan) {
      refValue.valan = valueRef.current?.valan;
    }
    setSearchParams(refValue);
  };

  const handleReset = () => {
    dispatch(setUserClearFilter(true));
    setSearchParams({ userId: loginId });
  };

  // column
  const columns = [
    {
      title: "SR NO.",
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
      width: "4rem",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "USER INFO",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      width: "14rem",
      render: (text, record) => (
        <div>{`${record?.userId} (${record?.accountName})`}</div>
      ),
    },
    {
      title: "VALAN",
      dataIndex: "valan",
      key: "valan",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        return !isLoading && <span>{record?.valanId?.valanName}</span>;
      },
    },
    {
      title: "TOTAL VALAN BILL",
      dataIndex: "totalValanBill",
      key: "totalValanBill",
      align: "center",
      width: "14rem",
      render: (text, record) => {
        const valanId = valueRef.current?.valan;
        return (
          !isLoading && (
            <StyledSpan>
              <div style={{ width: "80%" }}>
                {showValue(record?.totalValanBill)}
              </div>
              <PdfDiv>
                <FilePdfOutlined
                  onClick={() => {
                    navigate(`/valanBill/${record?.userId}`);
                    dispatch(
                      setSummaryDataFilter({
                        // ...valueRef.current,
                        withoutBrokerage: false,
                        parentId: valueRef.current?.userId
                          ? valueRef.current?.userId
                          : loginId,
                        valan: valueRef.current?.valan || null,
                      })
                    );
                  }}
                />
              </PdfDiv>
            </StyledSpan>
          )
        );
      },
    },
    {
      title: "BROKERAGE",
      dataIndex: "brokerage",
      key: "brokerage",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        return !isLoading && <div>{formatToINR(record?.brokerage || 0)}</div>;
      },
    },
    {
      title: "Broker 1",
      align: "center",
      width: "12rem",
      children: [
        {
          title: "Name",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            let broker1 = record?.brokerData?.[0];
            if (broker1) {
              return <div>{broker1?.accountName}</div>;
            } else {
              return "-";
            }
          },
        },
        {
          title: "%(Sharing)",
          dataIndex: "",
          align: "center",
          key: "time",
          width: "6rem",
          render: (text, record) => {
            let broker1 = record?.brokerData?.[0];
            if (broker1) {
              return <div>{formatToINR(broker1?.percentage)}</div>;
            } else {
              return "-";
            }
          },
        },
        {
          title: "BR(Sharing)",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            let broker1 = record?.brokerData?.[0];
            if (broker1) {
              return <div>{formatToINR(broker1?.brokerBrokerage)}</div>;
            } else {
              return "-";
            }
          },
        },
      ],
    },
    {
      title: "Broker 2",
      align: "center",
      width: "12rem",
      children: [
        {
          title: "Name",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            let broker2 = record?.brokerData?.[1];
            if (broker2) {
              return <div>{broker2?.accountName}</div>;
            } else {
              return "-";
            }
          },
        },
        {
          title: "%(Sharing)",
          dataIndex: "",
          align: "center",
          key: "time",
          width: "6rem",
          render: (text, record) => {
            let broker2 = record?.brokerData?.[1];
            if (broker2) {
              return <div>{formatToINR(broker2?.percentage)}</div>;
            } else {
              return "-";
            }
          },
        },
        {
          title: "BR(Sharing)",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            let broker2 = record?.brokerData?.[1];
            if (broker2) {
              return <div>{formatToINR(broker2?.brokerBrokerage)}</div>;
            } else {
              return "-";
            }
          },
        },
      ],
    },
    {
      title: "Broker 3",
      align: "center",
      width: "12rem",
      children: [
        {
          title: "Name",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            let broker3 = record?.brokerData?.[2];
            if (broker3) {
              return <div>{broker3?.accountName}</div>;
            } else {
              return "-";
            }
          },
        },
        {
          title: "%(Sharing)",
          dataIndex: "",
          align: "center",
          key: "time",
          width: "6rem",
          render: (text, record) => {
            let broker3 = record?.brokerData?.[2];
            if (broker3) {
              return <div>{formatToINR(broker3?.percentage)}</div>;
            } else {
              return "-";
            }
          },
        },
        {
          title: "BR(Sharing)",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            let broker3 = record?.brokerData?.[2];
            if (broker3) {
              return <div>{formatToINR(broker3?.brokerBrokerage)}</div>;
            } else {
              return "-";
            }
          },
        },
      ],
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row align={"bottom"} gutter={[20, 15]}>
          <ValanComponant valueRef={valueRef} setGetValan={setGetValan} />
          <UserFilterComponant
            valueRef={valueRef}
            multipleValueRef={multipleValueRef}
            userType={["Customer", "Broker"]}
            // role="customer"
          />
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <StyledButton type="primary" onClick={handleSubmit}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <StyledButton type="primary" onClick={handleReset}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={true}
        buttonTitle={buttonTitles}
        data={brokerSharingData || []}
        column={
          params?.brokerId
            ? columns
                ?.map((el) =>
                  el?.title === "Broker 1" ? { ...el, title: "Broker" } : el
                )
                ?.filter(
                  (el) => el?.title !== "Broker 2" && el?.title !== "Broker 3"
                )
            : columns
        }
        headingStyle={false}
        filtertype={"broker"}
        isLoading={isLoading}
        footer={brokerSharingData?.length > 0 ? true : false}
      />
    </>
  );
};

export default BrokerSharingReport;
