import React from "react";
import { Typography, Collapse, Space } from "antd";
import styled from "styled-components";
import ThemeCard from "../../components/ThemeCard";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const StyledContainer = styled.div`
  /* margin: 0 auto; */
  /* padding: 1.5rem; */
`;

const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 1.3rem;
  line-height: normal;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 1.5rem;

  .ant-collapse-header {
    padding: 0.5rem 1rem !important;
    background-color: ${(props) => props?.theme?.token?.colorPrimary};
    color: white !important;
  }
`;

const StyledPanel = styled(Panel)`
  font-size: 1.5rem;
`;

const RulesAndRegulation = () => {
  return (
    <StyledContainer>
      <ThemeCard
      // title={<StyledTitle level={3}>Rules and Regulations</StyledTitle>}
      >
        <Typography>
          <StyledTitle level={3}>Rules and Regulations</StyledTitle>

          <StyledCollapse defaultActiveKey={["1", "2", "3", "4", "5", "6"]}>
            <StyledPanel header="नियम और विनियम" key="6">
              <StyledSpace direction="vertical">
                <StyledParagraph>
                  • हमारे प्लेटफॉर्म में जॉबिंग और आर्बिट्रेज सख्त वर्जित है
                </StyledParagraph>
                <StyledParagraph>
                  • हमारे मंच में धोखाधड़ी और लाइन और चक्री व्यापार सख्त वर्जित
                  हैं
                </StyledParagraph>
                <StyledParagraph>
                  • अगर सेम आईपी ऐड्रेस से कई अकाउंट में ट्रेड मिल रहे हैं तो
                  उसे डिलीट किए जाएंगे।
                </StyledParagraph>
                <StyledParagraph>
                  • अगर किसी भी स्क्रिप्ट में डिविडेंड डिक्लेयर्ड होता है उस
                  स्क्रिप्ट में जिसकी भी जो भी पोजीशन रहेगी वह गये दिन के
                  क्लोजिंग रेट के हिसाब से क्लोज कर दी जाएगी।
                </StyledParagraph>
                <StyledParagraph>
                  • अगर किसी मास्टर के या किसी क्लाइंट के कई अकाउंट में सेम
                  पोजीशन या सेम ट्रेड्स मिलेंगे तो उसे भी रद्द किया जाएगा और
                  डिलीट किया जाएगा।
                </StyledParagraph>
                <StyledParagraph>
                  • किसी भी खाते में उपरोक्त प्रकार की गतिविधि पाए जाने पर, किसी
                  भी समय के दौरान ट्रेड हटा दिया जाएगा और ग्राहक / मास्टर /
                  प्रबंधक को सूचित किया जाएगा।
                </StyledParagraph>
                <StyledParagraph>
                  • ट्रेडिंग समय एफएनओ 9:16 से 3.30 एमसीएक्स 9:01 से 23:55
                </StyledParagraph>
                <StyledParagraph>
                  • कोई भी डाउटफुल ट्रेड मिलने पर उसे डिलीट किया जाएगा।
                </StyledParagraph>
                <StyledParagraph>
                  • जो भी स्क्रिप्ट बेन होगी उसे हटा दिया जाएगा।
                </StyledParagraph>
                <StyledParagraph>
                  • लॉकर / पार्किंग / स्टैंडिंग सिस्टम की अनुमति नहीं है इसलिए
                  नियमित रूप से व्यापार करें, अन्यथा कार्रवाई की जाएगी
                </StyledParagraph>
              </StyledSpace>
            </StyledPanel>

            <StyledPanel header="General Guidelines" key="1">
              <StyledSpace direction="vertical">
                <StyledParagraph>
                  • This platform is for educational purposes only. Real money
                  is not involved.
                </StyledParagraph>
                <StyledParagraph>
                  • Users must be 18 years or older. Respectful behavior towards
                  other users is mandatory.
                </StyledParagraph>
                <StyledParagraph>
                  • Do not share your account information with anyone. One
                  account per user is allowed.
                </StyledParagraph>
                <StyledParagraph>
                  • All trades must be simulated within the platform's
                  limitations.
                </StyledParagraph>
                <StyledParagraph>
                  • Market data may not be real-time and may have delays.
                </StyledParagraph>
                <StyledParagraph>
                  • Technical issues must be reported immediately and should not
                  be exploited.
                </StyledParagraph>
                <StyledParagraph>
                  • The platform owner reserves the right to modify these rules
                  or suspend accounts for violations.
                </StyledParagraph>
              </StyledSpace>
            </StyledPanel>

            <StyledPanel header="Platform Usage" key="2">
              <StyledSpace direction="vertical">
                <StyledParagraph>
                  • Don't manipulate market data or engage in unfair practices.
                </StyledParagraph>
                <StyledParagraph>
                  • Use educational resources provided within the platform.
                </StyledParagraph>
                <StyledParagraph>
                  • Share your learnings and engage in constructive discussions
                  with other users.
                </StyledParagraph>
                <StyledParagraph>
                  • Keep the platform environment positive and conducive to
                  learning.
                </StyledParagraph>
                <StyledParagraph>
                  • Report any inappropriate content or behavior immediately.
                </StyledParagraph>
              </StyledSpace>
            </StyledPanel>

            <StyledPanel header="Trading Guidelines" key="3">
              <StyledSpace direction="vertical">
                <StyledParagraph>
                  • Practice risk management tools like stop-loss and
                  take-profit orders.
                </StyledParagraph>
                <StyledParagraph>
                  • Understand the risks of leverage involved in F&O and margin
                  trading.
                </StyledParagraph>
                <StyledParagraph>
                  • Diversify your simulated portfolio across different asset
                  classes.
                </StyledParagraph>
                <StyledParagraph>
                  • Research and analyze before placing trades.
                </StyledParagraph>
                <StyledParagraph>
                  • Focus on learning from experiences and developing a trading
                  strategy.
                </StyledParagraph>
                <StyledParagraph>
                  • Don't chase trends or invest based on emotions.
                </StyledParagraph>
              </StyledSpace>
            </StyledPanel>

            <StyledPanel header="Additional Notes" key="4">
              <StyledSpace direction="vertical">
                <StyledParagraph>
                  • Simulated profits and losses do not guarantee real-world
                  success.
                </StyledParagraph>
                <StyledParagraph>
                  • Consider seeking professional guidance before entering
                  real-world trading.
                </StyledParagraph>
                <StyledParagraph>
                  • This platform is a stepping stone, not a replacement for
                  financial education and experience.
                </StyledParagraph>
                <StyledParagraph>
                  • Use trading journals and analysis tools to track your
                  progress.
                </StyledParagraph>
                <StyledParagraph>
                  • Remember, successful trading requires patience, discipline,
                  and continuous learning.
                </StyledParagraph>
              </StyledSpace>
            </StyledPanel>

            <StyledPanel header="Technical Information" key="5">
              <StyledSpace direction="vertical">
                <StyledParagraph>
                  • Intraday trades will automatically square-up once the market
                  closes.
                </StyledParagraph>
                <StyledParagraph>
                  • If you have market holdings & you take opposite trade as
                  INTRADAY then it will create new INTRADAY position.
                </StyledParagraph>
                <StyledParagraph>
                  • Please note that only one week's worth of historical data is
                  available for educational purposes.
                </StyledParagraph>
              </StyledSpace>
            </StyledPanel>
          </StyledCollapse>
        </Typography>
      </ThemeCard>
    </StyledContainer>
  );
};

export default RulesAndRegulation;
