import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const NotFound = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authSlice.accessToken);

  const backToHome = () => {
    if (accessToken) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Page not Found"
        extra={
          <Button type="primary" onClick={backToHome}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
