import { Tooltip } from "antd";
import React, { useState } from "react";
import { EditUserButton } from "../../../../styles/commonStyle";
import { EditButtonHold, EditButtonText } from "../style";
import { useNavigate } from "react-router-dom";
import useGetQueryParams from "../../../../hooks/useGetQueryParams";
import { useEditUser } from "../../../../APICall/apiHooks";

const EditUserButtonComponent = ({ item }) => {
  const navigate = useNavigate();
  const [pressTimer, setPressTimer] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  let isSquareUp = item?.isOnlyPositionSquareup;
  const { params } = useGetQueryParams();

  // custom hook for edit user, use for edit only position squareup directly
  const { editUserLoading, editUserMutate } = useEditUser({ item, params });

  const EditUserDetails = (id) => {
    if (item?.partnerShipType !== "rent") {
      navigate(`/users/edit-account/${id}`);
      // window.open(`/users/edit-account/${id}`, "_blank");
    } else {
      navigate(`/users/edit-rent-account/${id}`);
      // window.open(`/users/edit-rent-account/${id}`, "_blank");
    }
  };

  const handleStart = () => {
    const timer = setTimeout(() => {
      setApiCalled(true);
      let payload = {
        accountType: item?.accountType,
        accountDetails: {
          isOnlyPositionSquareup: !item?.isOnlyPositionSquareup,
        },
      };
      {
        isSquareUp !== null && editUserMutate(payload);
      }
    }, 2000);

    setPressTimer(timer);
    setApiCalled(false); // Reset the flag when starting a new press
  };

  const handleEnd = (id) => {
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);

      if (!apiCalled) {
        EditUserDetails(id);
      }
    }
  };
  return (
    <Tooltip placement="bottomLeft" title="Edit">
      <EditUserButton
        className="regularUserTableBtn"
        // onClick={() => EditUserDetails(item.userId)}
        onMouseDown={handleStart} // Desktop mouse down
        onMouseUp={() => handleEnd(item.userId)} // Desktop mouse up
        onMouseLeave={() => handleEnd(item.userId)} // Desktop edge case: mouse leaves the button
        onTouchStart={handleStart} // Mobile touch start
        onTouchEnd={() => handleEnd(item.userId)} // Mobile touch end
        loading={editUserLoading}
      >
        <EditButtonText squareUp={isSquareUp !== null}>E</EditButtonText>
        {isSquareUp !== null && (
          <EditButtonHold isSquareUp={isSquareUp}></EditButtonHold>
        )}
      </EditUserButton>
    </Tooltip>
  );
};

export default EditUserButtonComponent;
