import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Popover,
  Row,
  Skeleton,
  Spin,
  notification,
  theme,
} from "antd";
import ThemeInput from "../../../components/ThemeInput";
import ThemeCard from "../../../components/ThemeCard";
import { setNewAccountInfo } from "../../../store/slice/userAccountSlice";
import ThemeDropDown from "../../../components/ThemeDropDown";
import React, { Suspense, lazy, useEffect } from "react";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { useState } from "react";
import SetUserDetails from "../../../components/SetUserDetails/SetUserDetails";
import modifyInitialValuesObject from "./modifyInitialValuesObject";
import ThemeInputNumber, {
  converterFunction,
} from "../../../components/ThemeInputNumber/ThemeInputNumber";
import { handleMarketValidation } from "../../../Helper/functions";
import DefaultUserSetting from "./components/DefaultUserSetting";
import { AddButton, ContentDiv, ContentFooter } from "./style";
import { useGetFilteredUsers } from "../../../APICall/apiHooks";

const BasicInfoView = React.memo(({ is_edits, validateFields, parentForm }) => {
  const dispatch = useDispatch();
  const token = theme.useToken().token;

  // status
  const [userDataLoading, setuserDataLoading] = useState(false);
  const [selectedUserSetting, setSelectedUserSetting] = useState("");
  const [newValue, setNewValue] = useState(0);
  const [showModal, setShowModal] = useState({
    flage: false,
    name: "",
    action: "",
  });

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const basicInfo = useSelector((state) => state.userAccountSlice?.basicInfo);
  const parentBasicInfo = useSelector(
    (state) => state.authSlice?.MarketAccessDetails?.basicInfo
  );
  const temp = useSelector(
    (state) =>
      state.authSlice?.MarketAccessDetails?.accountDetails?.partnershipWith
  );
  const parentBalance =
    parentBasicInfo?.balance >= 0 ? parentBasicInfo?.balance : 0;
  const defaultUser = useSelector(
    (state) => state.userAccountSlice?.basicInfo?.defaultUser
  );
  const constantValue = useSelector(
    (state) => state.globleSlice?.constantValue
  );
  const InitialValue = useSelector((state) => state.globleSlice?.constantValue);
  const isRent = temp === "rent";
  const isMaster = accountType === "masterAdmin";
  const isCustomer = accountType === "customer";
  const importUserSettings = basicInfo.importUserSettings;

  // custom hook for get filtered users
  const { filteredData, filterDataLoading } = useGetFilteredUsers({
    type: accountType,
    editable: !is_edits,
  });

  //life cycle
  useEffect(() => {
    if (defaultUser) {
      setSelectedUserSetting("");
    }
  }, [defaultUser]);

  useEffect(() => {
    if (!importUserSettings) {
      setSelectedUserSetting("");
    }
  }, [importUserSettings]);

  useEffect(() => {
    setSelectedUserSetting("");
  }, [accountType, is_edits]);

  useEffect(() => {
    if (selectedUserSetting) {
      getuserDetails();
    }
  }, [selectedUserSetting]);

  //api call
  const getuserDetails = async () => {
    setuserDataLoading(true);
    await APICall("get", `${EndPoints.getUser}${selectedUserSetting}`)
      .then((response) => {
        // console.log("Account Users response", response.data);
        if (response?.status === 200 && response?.data) {
          const userInfo = response.data?.data;
          const m2m = accountType === "customer" ? true : false;
          const finalObj = SetUserDetails(userInfo, m2m, false, parentForm);

          dispatch(
            setNewAccountInfo({
              parent_key: "basicInfo",
              key: "defaultUser",
              value: "",
            })
          );
          let modedVals = modifyInitialValuesObject(finalObj);
          parentForm.setFieldsValue({
            ...parentForm.getFieldsValue(),
            ...modedVals,
          });
          if (finalObj?.accountDetails?.newPositionSquareupTime) {
            finalObj.accountDetails.newPositionSquareupTime = null;
          }
          dispatch(setNewAccountInfo(finalObj));
          handleMarketValidation(parentForm, dispatch);
          setTimeout(() => {
            validateFields();
          }, 600);
        } else {
          notification.error({ message: response?.data?.message });
        }
      })
      .catch((errorInfo) => {
        notification.error({ message: errorInfo?.message });
      })
      .finally(() => {
        setuserDataLoading(false);
      });
  };

  //functions
  const handleAdd = () => {
    let action = showModal?.action;
    let name = showModal?.name;

    parentForm.setFields([
      {
        name: `balance`,
        value:
          Number(parentForm.getFieldValue([`balance`]) || 0) +
          (action === "add" ? Number(newValue) : -Number(newValue)),
        touched: true,
      },
    ]);
    parentForm.validateFields([`balance`]);
    setShowModal({ flage: false, name: "", action: "" });
    setNewValue(0);
  };
  const handleOnChange = (e) => {
    setNewValue(e.target.value);
  };
  const handleCancel = () => {
    setShowModal({ flage: false, name: "", action: "" });
    setNewValue(0);
  };
  const content = () => {
    return (
      <ContentDiv>
        <ThemeInputNumber
          inputProps={{
            onChange: handleOnChange,
          }}
          extraProps={{
            showConversion: true,
            conversionValue: newValue,
          }}
          value={newValue}
          style={{ width: "100%" }}
        />
        <ContentFooter>
          <Button onClick={handleAdd} style={{ width: "50%" }}>
            Ok
          </Button>
          <Button onClick={handleCancel} style={{ width: "50%" }}>
            Cancle
          </Button>
        </ContentFooter>
      </ContentDiv>
    );
  };
  const showPopover = (name, value) => {
    setShowModal({ flage: true, name: name, action: value });
  };
  const handleResetField = (name) => {
    parentForm.setFields([
      {
        name: "balance",
        value: InitialValue.balance,
        touched: true,
      },
    ]);
    parentForm.validateFields(["balance"]);
  };
  const onChangeDropDown = (name, value, option) => {
    setSelectedUserSetting(value?.[0]);

    dispatch(
      setNewAccountInfo({
        parent_key: "basicInfo",
        key: name,
        value: value[0],
      })
    );
  };

  return (
    <ThemeCard title="Basic Info" bordered={false} style={{ width: "100%" }}>
      <Row
        gutter={[10, 10]}
        align={"center"}
        justify={accountType === "broker" ? "left" : "space-between"}
      >
        <Col xs={24} sm={12} md={8} lg={6} xl={isCustomer ? 3 : 4}>
          <ThemeInput
            title="Account Name"
            required
            inputProps={{
              name: "accountName",
            }}
            formProps={{
              name: "accountName",
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && value?.length <= 20) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        `${
                          value?.length > 20
                            ? "You can not add more then 20 character"
                            : "Please add account name"
                        } `
                      );
                    }
                  },
                }),
              ],
            }}
          />
        </Col>
        {is_edits ? (
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeInput
              title="Account Id"
              required
              formProps={{
                name: "accountId",
                disabled: true,
              }}
              inputProps={{
                name: "accountId",
                readOnly: "readOnly",
                // disabled: true,
              }}
              readOnly
            />
          </Col>
        ) : (
          <Col xs={24} sm={12} md={8} lg={6} xl={isCustomer ? 3 : 4}>
            <ThemeInput
              title="Account Password"
              required
              formProps={{
                name: "accountPassword",
                initalValue: "1234",
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      value = value?.toString();
                      if (value?.length >= 4 || is_edits) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Password must be at least 4 characters long."
                        );
                      }
                    },
                  }),
                ],
              }}
              inputProps={{
                name: "accountPassword",
              }}
            />
          </Col>
        )}

        {accountType !== "broker" && (
          <Col xs={24} sm={12} md={8} lg={6} xl={isCustomer ? 5 : 6}>
            <ThemeInputNumber
              title={"M2M Limit"}
              subTitle={"(Link Ledger)"}
              required
              active={basicInfo?.linkLedger}
              errorheight="2rem"
              activeColor={token.Checkbox.colorPrimary}
              isShowReset
              handleResetField={() => handleResetField("balance")}
              extraProps={{
                input_type: "number",
                showConversion: true,
                addonBefore: is_edits && (
                  <>
                    <Popover
                      title={showModal?.name}
                      open={
                        showModal?.flage &&
                        showModal?.name === "balance" &&
                        showModal.action === "add"
                      }
                      onClick={() => showPopover("balance", "add")}
                      content={content}
                    >
                      <AddButton isAdd token={token}>
                        +
                      </AddButton>
                    </Popover>
                  </>
                ),
                addonAfter: (
                  <div style={{ display: "flex", height: "100%" }}>
                    {is_edits && (
                      <Popover
                        title={showModal?.name}
                        open={
                          showModal?.flage &&
                          showModal?.name === "balance" &&
                          showModal.action === "remove"
                        }
                        onClick={() => showPopover("balance", "remove")}
                        content={content}
                      >
                        <AddButton token={token}>-</AddButton>
                      </Popover>
                    )}
                    <Form.Item
                      name="linkLedger"
                      valuePropName="checked"
                      style={{ padding: "1px 10px", marginBottom: "0rem" }}
                    >
                      <Checkbox name="linkLedger"></Checkbox>
                    </Form.Item>
                  </div>
                ),
              }}
              inputProps={{
                name: "balance",
                className: "addon-number-input",
                step: 1,
                width: "100%",
                suffix: is_edits
                  ? `/${converterFunction(basicInfo?.dopositeHistory)}`
                  : "",
              }}
              formProps={{
                name: "balance",
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        (value <= parentBalance &&
                          value !== "" &&
                          value !== null &&
                          value >= 0 &&
                          !is_edits) ||
                        ((isMaster || isRent) &&
                          value !== "" &&
                          value !== null &&
                          value >= 0 &&
                          !is_edits) ||
                        ((isMaster || isRent) &&
                          value !== "" &&
                          value !== null &&
                          value >= 0 &&
                          is_edits) ||
                        (is_edits &&
                          value !== "" &&
                          value !== null &&
                          value <= constantValue?.balance + parentBalance &&
                          value >= 0) ||
                        (is_edits &&
                          constantValue?.balance < 0 &&
                          value === constantValue?.balance)
                      ) {
                        return Promise.resolve();
                      } else {
                        if (isMaster || isRent) {
                          return Promise.reject(
                            `Please fill this field with positive number`
                          );
                        } else if (parentBalance === 0 && !is_edits) {
                          return Promise.reject(
                            `${parentBasicInfo.accountName}(${parentBasicInfo.userId}) don't have sufficient balance, Please enter 0`
                          );
                        } else if (is_edits) {
                          return Promise.reject(
                            `You can not add deposite balance less then 0 and more then ${
                              constantValue?.balance + parentBalance
                            }`
                          );
                        } else {
                          return Promise.reject(
                            `You can not add deposite balance less then 0 and more then ${parentBalance}`
                          );
                        }
                      }
                    },
                  }),
                ],
              }}
            />
          </Col>
        )}
        {accountType !== "broker" && !is_edits && (
          <Col xs={24} sm={12} md={8} lg={6} xl={isCustomer ? 3 : 4}>
            <ThemeInputNumber
              title={"Opening Balance"}
              extraProps={{
                input_type: "number",
                showConversion: true,
              }}
              inputProps={{
                name: "openingBalance",
              }}
              formProps={{
                name: "openingBalance",
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value >= 0 || !value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(`Only allow positive numbers`);
                      }
                    },
                  }),
                ],
              }}
            />
          </Col>
        )}

        {accountType !== "broker" && !is_edits && (
          <Col xs={24} sm={12} md={8} lg={6} xl={3} style={{ width: "100%" }}>
            <ThemeDropDown
              name={"importUserSettings"}
              title="Import User Settings"
              placeholder="Select user"
              options={filteredData}
              width={"auto"}
              showSearch
              $value={selectedUserSetting}
              $onChange={onChangeDropDown}
              disabled={userDataLoading}
              suffixIcon={<Spin size="small" spinning={userDataLoading} />}
              loadings={filterDataLoading ? "true" : "false"}
            />
          </Col>
        )}
        {accountType !== "broker" && !is_edits && (
          <DefaultUserSetting
            validateFields={validateFields}
            parentForm={parentForm}
          />
        )}
      </Row>
    </ThemeCard>
  );
});

export default BasicInfoView;
