import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webSocket: { data: {}, socket: false },
  liveUserInfo: {},
  totalLiveUser: "",
};

export const webSocketSlice = createSlice({
  name: "webSocketSlice",
  initialState,
  reducers: {
    resetWebSocket: () => ({ ...initialState }),
    setWebSocket(state, action) {
      const { id, data } = action?.payload;
      state.webSocket.data[id] = data;
    },
    setBatchUpdate: (state, action) => {
      let update = action.payload;
      update?.forEach((el) => {
        state.webSocket.data[el?.Symbol] = el;
      });
    },
    resetSocketData: (state, action) => {
      const symbols = action.payload;
      let ObjData = state.webSocket?.data;

      for (let key in ObjData) {
        let valid = symbols?.includes(key);
        if (!valid && key !== "256265" && key !== "265") {
          delete state.webSocket.data[key];
        }
      }
    },
    setLiveUserInfo: (state, action) => {
      const { value, total, obj } = action.payload;
      if (value) {
        value?.forEach((el) => {
          state.liveUserInfo[el?.userId] = el;
        });
        state.totalLiveUser = total;
      } else {
        state.liveUserInfo[obj?.userId] = obj;
        state.totalLiveUser = obj?.onlineCount;
      }
    },
    setSocketFlag: (state, action) => {
      state.webSocket.socket = action.payload;
    },
  },
});

export const {
  setWebSocket,
  resetWebSocket,
  setSocketFlag,
  resetSocketData,
  setBatchUpdate,
  setLiveUserInfo,
} = webSocketSlice.actions;
export default webSocketSlice.reducer;
