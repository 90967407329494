import {
  Form,
  Input,
  Button,
  Typography,
  theme,
  App,
  Avatar,
  message,
  Row,
  Col,
  Divider,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdditionalSharing,
  setAuth,
  setUserDetail,
  setUserIP,
} from "../../store/slice/authSlice";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { resetWebSocket } from "../../store/slice/webSocketSlice";
import { resetMarketWatchSlice } from "../../store/slice/marketWatchSlice";
import { resetUserAccountSlice } from "../../store/slice/userAccountSlice";
import axios from "axios";
import {
  marketDataList,
  setApiLink,
  setFilterUsers,
} from "../../store/slice/globleSlice";
import { useQueryClient } from "@tanstack/react-query";
import LogoImage from "../../Image/Bison_Capital.png";
import {
  AndroidFilled,
  AppleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import { useAuthChannel } from "../../hooks/useAuthChannel";
import { LoginDiv, LoginStyle } from "./Login.styles";
import { sequence } from "../../Helper/constant";
import { getApplicationLink } from "../../components/applicationLink";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const { broadcastLogin, broadcastLogout } = useAuthChannel();
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  // const socketContext = useSocket();
  // const { socketData, doSend, socket } = socketContext;

  //redux
  const userIP = useSelector((state) => state.authSlice?.user?.userIP);
  const apiLink = useSelector((state) => state?.globleSlice?.apiLink);

  //style
  const token = theme.useToken().token;
  const style = {
    inputItem: {
      marginBottom: "1.5rem",
    },
    input: {
      height: 44,
      border: 0,
      borderRadius: token.borderRadiusLG,
      backgroundColor: token.colorFillContent,
      "::placeholder": {
        color: token.colorTextPlaceholder, // Replace this with your desired placeholder color
      },
    },
    loginBtn: {
      borderRadius: token.borderRadiusLG,
      fontSize: token.fontSizeSM,
      height: 40,
      backgroundColor: token.colorBgActionButton,
      boxShadow: token.boxShadowNone,
    },
  };

  //lifecycle
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);
  useEffect(() => {
    dispatch(setAuth(null));
    dispatch(resetWebSocket());
    dispatch(resetMarketWatchSlice({ key: "all" }));
    dispatch(resetUserAccountSlice());
    getClientIP();
    queryClient.clear();
    getApplicationLink().then((data) => {
      dispatch(setApiLink(data));
    });
  }, []);

  //functions
  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        // Clear the deferredPrompt so it can only be used once.
        setDeferredPrompt(null);
      });
    }
  };

  const getClientIP = async () => {
    try {
      const response = await axios("https://api64.ipify.org?format=json");
      if (response.status === 200 && response.data) {
        const data = response.data;
        // console.log("🚀 ~ getClientIP ~ data:", data);
        // setClientIP(data?.ip);
        dispatch(setUserIP(data?.ip));
      } else {
      }
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const getMarketList = async (marketList) => {
    const sortedArray = marketList
      .sort((a, b) => {
        const indexA = sequence.indexOf(a.scriptName);
        const indexB = sequence.indexOf(b.scriptName);

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      })
      .map((item) => ({
        ...item,
        label: item.scriptName,
        value: item.scriptName,
      }));
    dispatch(marketDataList(sortedArray));
  };

  const handleLogin = async (values) => {
    const newValues = { ...values, loginType: "WEB", ipAddress: userIP };
    if (!isLoading) {
      form
        .validateFields()
        .then(async () => {
          setIsLoading(true);
          await APICall("post", EndPoints.login, newValues).then((response) => {
            let res = response?.data;
            if (response?.status === 200 && res) {
              broadcastLogout();
              let data = response?.data?.data;
              // console.log("🚀 ~ handleLogin ~ data:", data);
              queryClient.resetQueries();
              getMarketList(data?.marketList);
              dispatch(setAuth(data?.user?.token));
              dispatch(
                setUserDetail({ ...data?.user, ...data?.partnerShipWith })
              );
              dispatch(
                setAdditionalSharing({
                  ...data?.additionalSharing,
                  accountType: data?.user?.accountType,
                })
              );
              dispatch(setFilterUsers([data?.user?.userId]));
              localStorage.setItem("token", data?.user?.token);
              broadcastLogin(data?.user?.token);
              getClientIP();
              if (data?.user?.resetPassword === true) {
                navigate("/resetPassward");
              } else {
                navigate("/dashboard");
              }
              // messageApi.open({
              //   type: "success",
              //   content: `${res?.message}`,
              //   duration: 3,
              // });
              notification?.success({
                message: res?.message,
              });
            } else {
              if (res?.message) {
                // notification?.error({
                //   message: res?.message,
                // });
                messageApi.open({
                  type: "error",
                  content: `${res?.message}`,
                  duration: 3,
                });
              }
            }
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <LoginDiv>
      {contextHolder}
      <LoginStyle.RightView>
        <Avatar size={254} src={LogoImage} />
        <LoginStyle.Form
          form={form}
          onFinish={handleLogin}
          name="login-form"
          layout="vertical"
          initialValues={{
            remember: true,
            userId: "",
            password: "",
            loginType: "WEB",
          }}
        >
          <Row align={"center"} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
              <Typography.Title
                level={4}
                style={{
                  marginBlock: "0.4rem",
                  fontSize: token.fontSize,
                }}
              >
                Login
              </Typography.Title>
            </Col>
          </Row>
          <Row align={"center"} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
              <Typography.Paragraph
                level={3}
                style={{
                  marginBottom: "0.6rem",
                  fontSize: token.fontSizeSM,
                }}
              >
                Login with your userId
              </Typography.Paragraph>
            </Col>
          </Row>
          <Row align={"center"} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
              <Form.Item
                label=""
                name="userId"
                rules={[
                  { required: true, message: "Please input your User Id" },
                ]}
                style={style.inputItem}
              >
                <Input placeholder="User Id" style={style.input} />
              </Form.Item>
            </Col>
          </Row>
          <Row align={"center"} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
              <Form.Item
                label=""
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                style={style.inputItem}
              >
                <Input.Password placeholder="Password" style={style.input} />
              </Form.Item>
            </Col>
          </Row>
          <Row align={"center"} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={style.loginBtn}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Login
                </Button>
              </Form.Item>
              <Divider type="horizontal" />
              <div className="iconWraper">
                <a
                  className="dowload-app-button"
                  href={apiLink?.android}
                  target="blank"
                >
                  <AndroidFilled className="icon" />
                  <div className="iconText">
                    <Typography.Text>Download Android APK</Typography.Text>
                    {/* <Typography.Text>Android APK</Typography.Text> */}
                  </div>
                </a>
                <a
                  className="dowload-app-button"
                  href={apiLink?.apple}
                  target="blank"
                >
                  <AppleFilled className="icon" />
                  <div className="iconText">
                    <Typography.Text>Download Apple APK</Typography.Text>
                    {/* <Typography.Text>Apple APK</Typography.Text> */}
                  </div>
                </a>
                {deferredPrompt && (
                  <button
                    type="button"
                    className="dowload-app-button"
                    onClick={handleInstallClick}
                    disabled={deferredPrompt}
                  >
                    <DownloadOutlined className="icon" />{" "}
                    <div className="iconText">
                      <Typography.Text>Download Web App</Typography.Text>
                      {/* <Typography.Text> Web App</Typography.Text> */}
                    </div>
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </LoginStyle.Form>
      </LoginStyle.RightView>
    </LoginDiv>
  );
};

export default Login;
