import { Col } from "antd";
import ThemeDropDown from "../ThemeDropDown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { SEGMENTS } from "../../Helper/constant";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { useGetScript } from "../../APICall/apiHooks";

const MarketScriptList = React.memo(({ valueRef }) => {
  const { params } = useGetQueryParams();
  // state
  const [value, setValue] = useState({
    marketName: params?.marketName || null,
    symbolName: params?.symbolName || null,
  });
  // custom hook for get script list
  const { scriptList, scriptLoading } = useGetScript({
    marketName: SEGMENTS[value?.marketName],
  });
  const dispatch = useDispatch();

  //redux
  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const clearMarketList = useSelector(
    (state) => state.globleSlice?.userClearFilter
  );

  // life cycle
  useEffect(() => {
    if (clearMarketList) {
      setValue({ marketName: null, symbolName: null });
      dispatch(setUserClearFilter(false));
    }
  }, [clearMarketList]);

  useEffect(() => {
    if (valueRef.current) {
      valueRef.current = { ...valueRef.current, value };
    }
  }, [value]);

  //function
  const onChangeDropdown = (name, selectedValue, selectedOptions) => {
    if (name === "marketName" && selectedValue) {
      setValue({ [name]: selectedValue?.[0] });
    } else {
      setValue((pre) => ({ ...pre, [name]: selectedValue?.[0] }));
    }
  };

  return (
    <>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <ThemeDropDown
          title={"Market"}
          placeholder="Select"
          name="marketName"
          options={marketList}
          width={"98%"}
          showSearch={true}
          $onChange={onChangeDropdown}
          $value={value?.marketName}
          disabled={scriptLoading}
          // suffixIcon={<Spin size="small" spinning={scriptLoading} />}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <ThemeDropDown
          title={"Script Name"}
          placeholder="Script Name"
          name="symbolName"
          options={scriptList}
          width={"98%"}
          showSearch={true}
          $onChange={onChangeDropdown}
          $value={value?.symbolName}
          // suffixIcon={<Spin size="small" spinning={scriptLoading} />}
          loadings={scriptLoading ? "true" : "false"}
          disabled={scriptLoading}
        />
      </Col>
    </>
  );
});

export default MarketScriptList;
