import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeCard from "../../components/ThemeCard";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Tooltip,
  notification,
} from "antd";
import ThemeDropDown from "../../components/ThemeDropDown";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useDispatch, useSelector } from "react-redux";
import { setSummaryDataFilter } from "../../store/slice/globleSlice";
import ActionButton from "../../components/ActionButton";
import ThemeInput from "../../components/ThemeInput";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { UserLedgerButton } from "../../styles/commonStyle";
import { DeleteIcon, EditIcon } from "../Dashboard/style";
import { PdfDiv, buttonTitles } from "../SummaryReport/style";
import { FilePdfOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCalculateLedger } from "../../APICall/apiHooks";

const EntryTypeOption = [
  {
    label: "Valan Bill",
    value: "valanBill",
  },
  {
    label: "Seft Point",
    value: "selfPoint",
  },
  {
    label: "Upline Point",
    value: "upLinePoint",
  },
  {
    label: "Downline Point",
    value: "downLinePoint",
  },
  {
    label: "Valan Point",
    value: "valanPoint",
  },
  {
    label: "Point",
    value: "point",
  },
  {
    label: "JV",
    value: "JV",
  },
];

const BillMarketOptions = [
  {
    label: "Credit",
    value: "credit",
  },
  {
    label: "Debit",
    value: "debit",
  },
];

const UserLedger = () => {
  const userId = useParams()?.id;

  // state
  const [value, setValue] = useState({
    entryAfter: "",
    billType: "",
    entryType: "",
  });
  const [showModal, setShowModal] = useState({ flag: false, record: null });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //custom hook for ledger recalculate
  const { ledgerMutate, ledgerLoading } = useCalculateLedger({ id: userId });

  const queryClient = useQueryClient();

  //redux
  const isAuthorize = useSelector(
    (state) => state?.authSlice?.additionalSharing?.ledgerDelete
  );
  const isOwner = useSelector(
    (state) => state.authSlice?.userDetail?.accountType === "owner"
  );

  // api call
  const getledgerData = async () => {
    let url = `${EndPoints.getLedger}?clientId=${userId}`;
    for (let key in value) {
      if (value[key]) {
        if (key === "entryAfter") {
          url += `&${key}=${value[key]?.$d?.toISOString()}`;
        } else {
          url += `&${key}=${value[key]}`;
        }
      }
    }
    let response = await APICall("get", url);

    return response?.data?.data;
  };

  const {
    isLoading,
    data: ledgerData,
    error,
    refetch,
  } = useQuery({
    queryKey: ["getUserLedger"],
    queryFn: getledgerData,
    refetchOnMount: true,
  });

  if (error) notification.error({ message: error?.message });

  const handleSubmit = () => {
    refetch();
  };

  const deleteLedger = async (id) => {
    try {
      let response = await APICall(
        "delete",
        `${EndPoints.updateLedger}delete-ledger?ledgerId=${id}&recalculateLedgerBalance=false`
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        queryClient.refetchQueries({ queryKey: ["getUserLedger"] });
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    }
  };

  //function
  const handleDate = (date, string, name) => {
    setValue((pre) => ({ ...pre, [name]: date }));
  };

  const onChangeDropdown = (name, value, option) => {
    setValue((pre) => ({ ...pre, [name]: value?.[0] ? value[0] : value }));
  };

  const handleEdit = (record) => {
    setShowModal({ flag: true, record: record });
  };

  const handleCancel = () => {
    setShowModal({ flag: false, record: null });
  };

  // column
  const columns = [
    {
      title: "Sr No.",
      dataIndex: "date",
      key: "date",
      width: "5rem",
      align: "center",
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "CLIENT INFO",
      dataIndex: "client",
      key: "client",
      align: "left",
      render: (text, record) => {
        return (
          <div style={{ textAlign: "left" }}>
            {`${record?.userId} (${record?.accountName})`}
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.date, true);
        return <span>{finalDate}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "left",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "debit") {
          return (
            <div style={{ color: "red" }}>{formatToINR(record?.amount)}</div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "credit") {
          return (
            <div style={{ color: "green" }}>{formatToINR(record?.amount)}</div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "ledgerBalance",
      key: "ledgerBalance",
      align: "center",
      render: (text, record) => {
        const ledgerBalance = record?.ledgerBalance;
        return (
          <div style={{ color: ledgerBalance < 0 ? "red" : "green" }}>
            {formatToINR(ledgerBalance)}
          </div>
        );
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      align: "left",
      render: (text, record) => {
        return <div>{record?.remark}</div>;
      },
    },
    {
      title: "Bill",
      dataIndex: "bill",
      key: "bill",
      align: "center",
      render: (text, record) => {
        const showPDF =
          record?.type === "valanBill" || record?.type === "valanPoint";
        const showEditDelete =
          record?.type === "point" || record?.type === "JV" || isOwner;
        return (
          <Row justify={"space-evenly"}>
            <PdfDiv>
              {showPDF ? (
                <FilePdfOutlined
                  onClick={() => {
                    navigate(`/valanBill/${record?.userId}`);
                    dispatch(
                      setSummaryDataFilter({
                        withoutBrokerage: false,
                        valan: record?.valanId,
                      })
                    );
                  }}
                />
              ) : (
                "-"
              )}
            </PdfDiv>
            {isAuthorize && showEditDelete && (
              <EditIcon onClick={() => handleEdit(record)} />
            )}
            {isAuthorize && showEditDelete && (
              <Popconfirm
                title="delete the ledger"
                description="Are you sure to delete this ledger?"
                onConfirm={() => deleteLedger(record?._id)}
                placement="topRight"
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon />
              </Popconfirm>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]} justify="left" align="middle">
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDatePicker
              title="Entry After"
              name="entryAfter"
              placeholder="Select"
              onChange={(date, string) =>
                handleDate(date, string, "entryAfter")
              }
              width={"100%"}
              value={value?.entryAfter}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title="Bill Type"
              name="billType"
              placeholder="Select"
              width={"100%"}
              options={EntryTypeOption}
              $onChange={onChangeDropdown}
              value={value.billType}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title="Entry Type"
              name="entryType"
              placeholder="Select"
              width={"100%"}
              options={BillMarketOptions}
              $onChange={onChangeDropdown}
              value={value?.entryType}
            />
          </Col>
          <Col style={{ display: "flex", gap: "0.5rem", marginTop: "1.5rem" }}>
            {`Ledger Balance: `}
            <p
              style={{
                color: ledgerData?.ledgerBalance >= 0 ? "green" : "red",
              }}
            >
              {ledgerData?.ledgerBalance}
            </p>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={5}
            lg={{
              offset: 3,
              span: 3,
            }}
            xl={{
              offset: 4,
              span: 3,
            }}
            style={{ alignSelf: "end" }}
          >
            <UserLedgerButton
              type="primary"
              onClick={handleSubmit}
              style={{ width: "100%" }}
            >
              Find
            </UserLedgerButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCardTable
        buttonType={true}
        buttonTitle={buttonTitles}
        data={ledgerData?.entries}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading}
        filtertype={"ledger"}
      />
      <Row align={"end"} gutter={[10, 10]} style={{ marginTop: "0.5rem" }}>
        {isOwner && (
          <Col>
            <Tooltip title="Re-Calculate All Ledger">
              <Popconfirm
                title="Re-Calculate All Ledger"
                description="Are you sure to re-calculate all users ledger?"
                onConfirm={() => ledgerMutate("all")}
                placement="top"
              >
                <Button type="primary" loading={ledgerLoading}>
                  Re-Ledger ALL
                </Button>
              </Popconfirm>
            </Tooltip>
          </Col>
        )}
        <Col>
          <Tooltip title="Re-Calculate Ledger">
            <Popconfirm
              title="Re-Calculate Ledger"
              description="Are you sure to re-calculate ledger?"
              onConfirm={ledgerMutate}
              placement="topLeft"
            >
              <Button type="primary" loading={ledgerLoading}>
                Re-Ledger
              </Button>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
      {showModal.flag && (
        <EditLedger handleCancel={handleCancel} record={showModal?.record} />
      )}
    </>
  );
};

export default UserLedger;

export const EditLedger = ({ handleCancel, record }) => {
  const queryClient = useQueryClient();
  // state
  const [data, setData] = useState({
    amount: record?.amount,
    remark: null,
  });
  const [loading, setLoading] = useState(false);

  // api call
  const handleSubmit = async () => {
    if (!data?.amount) {
      notification.error({ message: "Please fill amount before submit" });
      return;
    }
    setLoading(true);
    try {
      let response = await APICall(
        "PUT",
        `${EndPoints.updateLedger}edit-ledger/${record?._id}`,
        data
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        queryClient.refetchQueries({ queryKey: ["getUserLedger"] });
        handleCancel();
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // function
  const onChangeValue = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <Modal
      title={
        <Row justify={"left"}>
          <Col span={12}>{`Edit Amount`}</Col>
          <Col span={10}>{`${record?.accountName} (${record?.userId})`}</Col>
        </Row>
      }
      open={true}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <ThemeCard bordered={false}>
        <Row
          justify={"space-around"}
          gutter={[10, 10]}
          // align={"middle"}
        >
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Amount"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "amount",
                name: "amount",
                value: data?.amount,
                onChange: onChangeValue,
              }}
            />
          </Col>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title={`${record?.entryType === "credit" ? "Credit" : "Debit"}`}
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                // placeholder: "LOT",
                name: "lot",
                value: record?.amount,
                // onChange: onChangeValue,
                readOnly: "true",
                // onBlur: onChangeValue,
                // disabled: record?.marketName === "NSE EQU" ? true : false,
              }}
            />
          </Col>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Balance"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "Balance",
                name: "balance",
                value: record?.ledgerBalance,
                // onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
                readOnly: "true",
              }}
            />
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: "1rem" }}>
          <Col span={22}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Remark"
              className="ThemeInput"
              inputProps={{
                placeholder: "Remark",
                name: "remark",
                value: data?.remark,
                onChange: onChangeValue,
              }}
            />
          </Col>
        </Row>
        <Row
          justify={"center"}
          gutter={[20, 20]}
          style={{ marginTop: "2rem", gap: "2rem" }}
        >
          <ActionButton
            title={"Submit"}
            onClick={handleSubmit}
            loading={loading}
          />
          <ActionButton
            title={"Cancel"}
            buttonType="cancel"
            onClick={handleCancel}
          />
        </Row>
      </ThemeCard>
    </Modal>
  );
};
