import React, { useEffect, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Form, Radio, Row, notification } from "antd";
import ThemeInput from "../../components/ThemeInput";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import ThemeDropDown from "../../components/ThemeDropDown";
import { useSelector } from "react-redux";
import { allOption } from "../../Helper/constant";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { ThemeForm, SubmitButton, LedgerSuffix } from "./style";
import { useWatch } from "antd/es/form/Form";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import { useGetChildUsers } from "../../APICall/apiHooks";
import dayjs from "dayjs";

function AccountPointEntry() {
  // state
  const [childOption, setChildOption] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();

  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const formAccountType = useWatch("accountType", form);

  // custom hook for  childUser api call
  const { isLoading, childUsers: childData } = useGetChildUsers({
    type: "onlychild",
    id: userDetail?.userId,
  });

  //api call
  const handleSubmit = async (payload) => {
    // make payload with account type and account id
    let obj = {
      ...payload,
      accountType: payload?.accountType?.[0],
      accountId: payload?.accountId?.[0],
      remark: payload?.remark || null,
      type: "point",
    };
    setSubmitLoading(true);
    try {
      let response = await APICall("post", EndPoints.createLedger, obj);
      if (response.status === 200) {
        notification.success({ message: response.data?.message });
        form.resetFields();
      } else {
        notification.error({ message: response.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setSubmitLoading(false);
    }
  };

  // life cycle
  useEffect(() => {
    //saparete child data according account type and set account type option
    if (childData) {
      const saparateData = childData?.reduce((acc, el) => {
        if (acc[el?.accountType]) {
          acc[el?.accountType].push({
            label: `${el?.accountName} (${el?.userId})`,
            value: el?.userId,
            ledgerBalance: el?.ledgerBalance,
          });
        } else {
          acc[el?.accountType] = [
            {
              label: `${el?.accountName} (${el?.userId})`,
              value: el?.userId,
              ledgerBalance: el?.ledgerBalance,
            },
          ];
        }
        if (!acc["all"]) {
          acc["all"] = [
            {
              label: `${el?.accountName} (${el?.userId})`,
              value: el?.userId,
              ledgerBalance: el?.ledgerBalance,
            },
          ];
        } else {
          acc["all"].push({
            label: `${el?.accountName} (${el?.userId})`,
            value: el?.userId,
            ledgerBalance: el?.ledgerBalance,
          });
        }

        return acc;
      }, {});

      setChildOption(saparateData);
    }
  }, [childData]);

  // function
  const handleCancel = () => {
    form.resetFields();
  };

  const handleFaild = (error) => {
    notification.error({ message: error?.errorFields?.[0]?.errors?.[0] });
  };

  const handleFinish = (payload) => {
    handleSubmit(payload);
  };
  // const accountIds = form.getFieldValue("accountId");
  const getaccountId = useWatch("accountId", form);
  const ledgerBalance = childData?.find(
    (el) => el?.userId === getaccountId?.[0]
  )?.ledgerBalance;

  return (
    <>
      <ThemeCard title={"Account Point Entry"}>
        <ThemeForm
          name="basic"
          form={form}
          onFinishFailed={handleFaild}
          onFinish={handleFinish}
          autoComplete="off"
          labelCol={{ xs: 24, sm: 8, md: 10, lg: 10, xl: 10 }}
          wrapperCol={{ xs: 24, sm: 12, md: 10, lg: 8, xl: 6 }}
          style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          <ThemeDropDown
            name={"accountType"}
            placeholder="Select"
            onChange={() => form.setFieldValue("accountId", null)}
            showSearch={true}
            options={allOption?.filter(
              (item) => item.authorityLevel > userDetail.authorityLevel
            )}
            disabled={isLoading}
            form_props={{
              name: "accountType",
              label: "Account Type",
              initialValue: ["all"],
              rules: [{ required: true }],
            }}
          />

          <ThemeDropDown
            name={"accountId"}
            placeholder="Select"
            showSearch={true}
            options={childOption[formAccountType?.[0]]}
            required
            suffixIcon={
              <LedgerSuffix ledgerBalance={ledgerBalance}>
                {ledgerBalance}
              </LedgerSuffix>
            }
            form_props={{
              name: "accountId",
              label: "Account Id",
              rules: [{ required: true }],
            }}
          />

          <Form.Item
            label="Type"
            name="entryType"
            initialValue={"deposit"}
            rules={[{ required: true }]}
          >
            <Radio.Group name="entryType" style={{ marginLeft: "1rem" }}>
              <Radio value={"deposit"}>deposit</Radio>
              <Radio value={"withdrow"}>Withdraw</Radio>
            </Radio.Group>
          </Form.Item>

          <ThemeDatePicker
            name="date"
            width={"100%"}
            formProps={{
              name: "date",
              label: "Date",
              initialValue: dayjs(Date.now()),
              rules: [{ required: true }],
            }}
          />
          <ThemeInputNumber
            marginBottom={"0rem"}
            inputProps={{
              name: "point",
              step: 1,
            }}
            formProps={{
              name: "point",
              label: "Point",
              rules: [{ required: true }],
            }}
          />
          <ThemeInput
            inputProps={{ name: "remark" }}
            formProps={{
              name: "remark",
              label: "Remark",
            }}
          />

          <Row
            justify={"center"}
            style={{ marginTop: "2rem" }}
            gutter={[10, 20]}
          >
            <Col xs={24} sm={8} md={4} lg={4} xl={3}>
              <SubmitButton
                loading={submitLoading}
                type="primary"
                htmlType="submit"
              >
                Submit
              </SubmitButton>
            </Col>
            <Col xs={24} sm={8} md={4} lg={4} xl={3}>
              <SubmitButton remove={"true"} onClick={handleCancel}>
                Cancel
              </SubmitButton>
            </Col>
          </Row>
        </ThemeForm>
      </ThemeCard>
    </>
  );
}

export default AccountPointEntry;
