import { useEffect, useCallback } from "react";

const authChannel = new BroadcastChannel("auth_channel");

export const useAuthChannel = () => {
  const broadcastLogin = useCallback((token) => {
    authChannel.postMessage({ type: "LOGIN", token });
  }, []);

  const broadcastLogout = useCallback(() => {
    authChannel.postMessage({ type: "LOGOUT" });
  }, []);

  // life cycle
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "LOGIN") {
        // Handle login in other tabs
        localStorage.setItem("token", event.data.token);
        window.location.reload();
      } else if (event.data.type === "LOGOUT") {
        // Handle logout in other tabs
        localStorage.removeItem("token");
        window.location.reload();
      }
    };

    authChannel.addEventListener("message", handleMessage);
    return () => authChannel.removeEventListener("message", handleMessage);
  }, []);

  return { broadcastLogin, broadcastLogout };
};
