import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/Auth/Login";
import Home from "./pages/Home";
import Users from "./pages/Users";
import NewAccount from "./pages/Users/NewAccount";
import Accounts from "./pages/Users/Accounts";
import MainLayout from "./pages/MainLayout";
import MarketWatch from "./pages/MarketWatch";
import { ProtectedRoute } from "./components/ProtectedRoute";
import NotFound from "./components/NotFound";
import Navbar from "./components/NavBar";
import Header from "./components/Header";
import RootCommonFunction from "./components/RootCommonFunction";
import AppStyle from "./App.style";
import "./App.css";
import ScrollToTopButton from "./components/ScrollToTop";
import DashboardScreen from "./pages/Dashboard";
import SocketProvider from "./components/Socket";
import SummaryReport from "./pages/SummaryReport";
import TradingTrade from "./pages/TradingTrade";
import TradingBlocked from "./pages/TradingBlocked";
import TradingPosition from "./pages/TradingPosition";
import TradeLog from "./pages/TradeLog";
import RejectionLog from "./pages/RejectionLog";
import BlukTrading from "./pages/BulkTrading";
import BrokerageReport from "./pages/BrokerageReport";
import TradeReport from "./pages/TradeReport";
import ProfitLossReport from "./pages/ProfitLossReport";
import AccountPointLedger from "./pages/AccountPointLedger";
import AccountPointEntry from "./pages/AccountPointEntry";
import AccountPointJVEntry from "./pages/AccountPointJVEntry";
import { jwtDecode } from "jwt-decode";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { setAuth } from "./store/slice/authSlice";
import ExpirySetting from "./pages/ExpirySetting";
import ResetPassward from "./pages/ResetPassward/ResetPassward";
import MarketSetting from "./pages/MarketSetting/MarketSetting";
import Profile from "./pages/Profile/Profile";
import ReportCart from "./pages/SummaryReport/ReportCart";
import ScrollBar from "./components/NavBar/ScrollBar";
import UserLedger from "./pages/UserLedger/UserLedger";
import M2mAlertLog from "./pages/M2mAlertLog/M2mAlertLog";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PointLedgerLog from "./pages/PointLedgerLog";
import UserEditLog from "./pages/UserEditLog/UserEditLog";
import CreateRentAccount from "./pages/Users/RentAccount/CreateRentAccount";
import { useAuthChannel } from "./hooks/useAuthChannel";
import BrokerSharingReport from "./pages/BrokerSharingReport/BrokerSharingReport";
import ValanSetting from "./pages/ValanSetting";
import IPAddressReport from "./pages/IPAddressReport/IPAddressReport";
import UserProfile from "./pages/Users/Accounts/UserProfile";
import RulesAndRegulation from "./pages/Rules";

function App() {
  const dispatch = useDispatch();

  // broadcast logout when change login from another tab in same browser
  const { broadcastLogout } = useAuthChannel();

  //redux
  const accessToken = useSelector((state) => state.authSlice.accessToken);
  const loginUser = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isCustomer = loginUser === "customer";
  const isOwner = loginUser === "owner";
  const isBroker = loginUser === "broker";
  const additionalSharing = useSelector(
    (state) => state.authSlice?.additionalSharing
  );
  const showValanSetting = additionalSharing?.valanDelete;
  const showPointEntry = additionalSharing?.accountPointEntry;
  const showNewAccount = additionalSharing?.newClientAdd;
  const showIpAddressReport = additionalSharing?.ipAddressReport;
  const location = useLocation();

  const { exp } = useMemo(() => {
    if (accessToken) {
      return jwtDecode(accessToken);
    } else {
      return 0;
    }
  }, [accessToken]);

  useLayoutEffect(() => {
    let logoutTime = exp * 1000;
    if (!exp || Date.now() >= logoutTime) {
      dispatch(setAuth(null));
      return;
    }
  });

  useEffect(() => {
    function adjustTopProperty(e, isZero) {
      const stickyHeader = document.querySelector(".sticky-main-header");
      if (stickyHeader) {
        const headerHeight = stickyHeader.offsetHeight;

        const targetElements = document.querySelectorAll(
          ".ant-table-sticky-holder"
        );
        targetElements.forEach((targetElement) => {
          targetElement.style.top = `${
            isZero || document?.fullscreenElement ? 0 : headerHeight
          }px`;
        });
      }
    }
    window.addEventListener("load", adjustTopProperty);
    window.addEventListener("resize", adjustTopProperty);
    adjustTopProperty();
    document.addEventListener("fullscreenchange", (e) => {
      document?.fullscreenElement
        ? adjustTopProperty(e, true)
        : adjustTopProperty(e, false);
    });
  }, [location]);

  // useLayoutEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       window.location.reload();
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  return (
    <SocketProvider>
      <AppStyle.Wrapper>
        <AppStyle.CSSReset />
        <RootCommonFunction />
        {accessToken ? (
          <div className="sticky-main-header">
            <Header />
            <Navbar />
            <ScrollBar />
          </div>
        ) : null}
        <AppStyle.Main $fullHeight={!accessToken}>
          <ScrollToTopButton />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/resetPassward"
              element={
                // <ProtectedRoute>
                <ResetPassward />
                // </ProtectedRoute>
              }
            />
            <Route
              path="rules-and-regulation"
              element={<RulesAndRegulation />}
            />
            {!isBroker && !isOwner && (
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
            )}

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <MainLayout />
                </ProtectedRoute>
              }
            >
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    {/* <Home /> */}
                    <DashboardScreen />
                  </ProtectedRoute>
                }
              />

              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    {/* <Home /> */}
                    <DashboardScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="market"
                element={
                  <ProtectedRoute>
                    <MarketWatch />
                  </ProtectedRoute>
                }
              />

              <Route
                path="report"
                element={
                  <ProtectedRoute>
                    <SummaryReport />
                  </ProtectedRoute>
                }
              />

              <Route
                path={`valanBill/:combinedParams`}
                element={
                  <ProtectedRoute>
                    <ReportCart />
                  </ProtectedRoute>
                }
              />

              <Route
                path="trading"
                // element={
                //   <ProtectedRoute>
                //     <Home />
                //   </ProtectedRoute>
                // }
              />
              <Route
                path="trading/trade"
                element={
                  <ProtectedRoute>
                    <TradingTrade />
                  </ProtectedRoute>
                }
              />
              <Route
                path="trading/blocked-scripts"
                element={
                  <ProtectedRoute>
                    <TradingBlocked />
                  </ProtectedRoute>
                }
              />
              <Route
                path="trading/position"
                element={
                  <ProtectedRoute>
                    <TradingPosition />
                  </ProtectedRoute>
                }
              />
              {!isCustomer && !isBroker && (
                <Route
                  path="users"
                  element={
                    <ProtectedRoute>
                      <Users />
                    </ProtectedRoute>
                  }
                >
                  {showNewAccount && (
                    <Route
                      path="new-account"
                      key={"new-account"}
                      element={
                        <ProtectedRoute>
                          <NewAccount is_edits={false} key="new" />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  <Route
                    key={"edit-account"}
                    path={`edit-account/:id`}
                    element={
                      <ProtectedRoute>
                        <NewAccount is_edits={true} key={`edit-account`} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={`accounts`}
                    element={
                      <ProtectedRoute>
                        <Accounts />
                      </ProtectedRoute>
                    }
                  />
                  {loginUser !== "dealer" && showNewAccount && (
                    <Route
                      path="create-rent-account"
                      element={
                        <ProtectedRoute>
                          <CreateRentAccount key={"createRentAccount"} />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  {loginUser !== "dealer" && (
                    <Route
                      path={`edit-rent-account/:id`}
                      element={
                        <ProtectedRoute>
                          <CreateRentAccount
                            is_edits={true}
                            key={"editRentAccount"}
                          />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  {/* <Route
                    path={`user-profile/:id`}
                    element={
                      <ProtectedRoute>
                        <UserProfile />
                      </ProtectedRoute>
                    }
                  /> */}
                </Route>
              )}
              {!isCustomer && (
                <Route
                  path={`user-ledger/:id`}
                  element={
                    <ProtectedRoute>
                      <UserLedger />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="utilities"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="utilities/trade-log"
                  element={
                    <ProtectedRoute>
                      <TradeLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="/utilities/user-edit-log"
                  element={
                    <ProtectedRoute>
                      <UserEditLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="utilities/point-ledger-log"
                  element={
                    <ProtectedRoute>
                      <PointLedgerLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* <Route
                path="utilities/cash-ledger-log"
                element={
                  <ProtectedRoute>
                    <CashLedgerLog />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="utilities/rejection-log"
                element={
                  <ProtectedRoute>
                    <RejectionLog />
                  </ProtectedRoute>
                }
              />
              {!isCustomer && (
                <Route
                  path="utilities/M2mAlert-log"
                  element={
                    <ProtectedRoute>
                      <M2mAlertLog />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="utilities/bulk-trading"
                  element={
                    <ProtectedRoute>
                      <BlukTrading />
                    </ProtectedRoute>
                  }
                />
              )}
              <Route
                path="account-point-ledger"
                element={
                  <ProtectedRoute>
                    <AccountPointLedger />
                  </ProtectedRoute>
                }
              />
              {!isCustomer && showPointEntry && (
                <Route
                  path="account-point-entry"
                  element={
                    <ProtectedRoute>
                      <AccountPointEntry />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && showPointEntry && (
                <Route
                  path="account-point-jv-entry"
                  element={
                    <ProtectedRoute>
                      <AccountPointJVEntry />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="reports/brokerage-report"
                  element={
                    <ProtectedRoute>
                      <BrokerageReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {showIpAddressReport && !isCustomer && !isBroker && (
                <Route
                  path="reports/IP-address-report"
                  element={
                    <ProtectedRoute>
                      <IPAddressReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && !isOwner && !isBroker && (
                <Route
                  path="reports/broker-sharing-report"
                  element={
                    <ProtectedRoute>
                      <BrokerSharingReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="reports/trade-report"
                  element={
                    <ProtectedRoute>
                      <TradeReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {!isCustomer && (
                <Route
                  path="reports/profit-and-loss-percentage"
                  element={
                    <ProtectedRoute>
                      <ProfitLossReport />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* <Route
                path="settings/script-block-allow"
                element={
                  <ProtectedRoute>
                    <ScriptBlockAllow />
                  </ProtectedRoute>
                }
              /> */}
              {/* <Route
                path="settings/notification"
                element={
                  <ProtectedRoute>
                    <NotificationPage />
                  </ProtectedRoute>
                }
              /> */}

              {(isOwner || (showValanSetting && !isCustomer && !isBroker)) && (
                <Route
                  path="settings/valan-setting"
                  element={
                    <ProtectedRoute>
                      <ValanSetting />
                    </ProtectedRoute>
                  }
                />
              )}

              {isOwner && (
                <Route
                  path="settings/expiry-setting"
                  element={
                    <ProtectedRoute>
                      <ExpirySetting />
                    </ProtectedRoute>
                  }
                />
              )}
              {isOwner && (
                <Route
                  path="settings/market-setting"
                  element={
                    <ProtectedRoute>
                      <MarketSetting />
                    </ProtectedRoute>
                  }
                />
              )}
            </Route>
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <NotFound />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AppStyle.Main>
        {/* scroll to top is not working  */}
      </AppStyle.Wrapper>
    </SocketProvider>
  );
}

export default App;
