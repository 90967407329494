import React, { useEffect, useState } from "react";
import ThemeDatePicker from "../ThemeDatePicker";
import { Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserClearFilter,
  setValanValue,
} from "../../store/slice/globleSlice";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import dayjs from "dayjs";

const initialState = {
  startDate: null,
  endDate: null,
};

const GetDate = React.memo(({ valueRef }) => {
  //state
  const [value, setValue] = useState(initialState);
  const dispatch = useDispatch();
  const { params } = useGetQueryParams();

  //redux
  const clear = useSelector((state) => state.globleSlice?.userClearFilter);
  const setDate = useSelector((state) => state.globleSlice?.clearValan);

  // life cycle
  useEffect(() => {
    if (params?.startDate || params?.endDate) {
      setValue({
        startDate: params?.startDate ? dayjs(params.startDate) : null,
        endDate: params?.endDate ? dayjs(params.endDate) : null,
      });
      valueRef.current = {
        ...valueRef.current,
        startDate: params?.startDate ? dayjs(params.startDate) : null,
        endDate: params?.endDate ? dayjs(params.endDate) : null,
      };
    }
  }, []);

  useEffect(() => {
    if (setDate) {
      setValue(initialState);
      valueRef.current = { ...valueRef.current, ...initialState };
    }
  }, [setDate]);

  useEffect(() => {
    if (clear) {
      setValue(initialState);
      valueRef.current = { ...valueRef.current, ...initialState };
      dispatch(setUserClearFilter(false));
    }
  }, [clear]);

  // function
  const handleDate = (date, string, name) => {
    setValue((pre) => ({ ...pre, [name]: date }));
    valueRef.current = { ...valueRef.current, [name]: date };
    if (date) {
      dispatch(setValanValue(false));
    }
  };

  return (
    <>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <ThemeDatePicker
          title="Start Date"
          width="100%"
          showTime={{
            format: "HH:mm",
            defaultValue: dayjs().hour(0).minute(0),
          }}
          format="YYYY-MM-DD HH:mm"
          name="startDate"
          placeholder="Start Date"
          onChange={(date, string) => handleDate(date, string, "startDate")}
          value={value.startDate}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <ThemeDatePicker
          title="End Date"
          width="100%"
          showTime={{
            format: "HH:mm",
            // defaultValue: dayjs().hour(0).minute(0),
          }}
          format="YYYY-MM-DD HH:mm"
          name="endDate"
          placeholder="End Date"
          onChange={(date, string) => handleDate(date, string, "endDate")}
          value={value.endDate}
        />
      </Col>
    </>
  );
});

export default GetDate;
