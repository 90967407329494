import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from ".";
import EndPoints from "./EndPoints";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useGetChildUsers = ({ id, type }) => {
  const getChildUser = async () => {
    let url = `${EndPoints.getChildUsers}${id}`;
    if (type) {
      url += `&type=${type}`;
    }
    let response = await APICall("get", url);
    return response?.data?.data;
  };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["childUser", id],
    queryFn: getChildUser,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    refetchOnMount: true,
    keepPreviousData: false,
    enabled: !!id,
  });

  return {
    userDetails: data?.userDetail,
    childUsers: data?.childUsers,
    isLoading: isLoading || isFetching,
  };
};

export const useGetUplineUsers = ({ id }) => {
  const getUplineUser = async () => {
    let response = await APICall("get", `${EndPoints.getUpline}${id}`);
    return response?.data?.data;
  };

  const { isLoading, data } = useQuery({
    queryKey: ["uplineUsers", id],
    queryFn: getUplineUser,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    enabled: !!id,
  });

  return {
    upLineLoading: isLoading,
    upLineData: data,
  };
};

export const useGetFilteredUsers = ({ type, editable }) => {
  // state
  const [filteredData, setFilteredData] = useState([]);

  // api call function
  const getFilteredUser = async () => {
    let respnose = await APICall("get", `${EndPoints.getUserByFilter}${type}`);
    return respnose?.data?.data?.finalResult;
  };
  const { isLoading, isFetching, data } = useQuery({
    queryKey: ["filteredUsers", type],
    queryFn: getFilteredUser,
    enabled: editable,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    refetchOnMount: true,
  });

  // life cycle
  useEffect(() => {
    // set filtered data
    if (data) {
      setFilteredData(() => {
        return data?.map((el) => {
          return {
            label: `${el?.userId} (${el?.accountName})`,
            value: el?.userId,
          };
        });
      });
    }
  }, [data]);

  return {
    filterDataLoading: isLoading || isFetching,
    filteredData,
  };
};

export const useGetScript = ({ marketName }) => {
  //state
  const [scriptList, setScriptList] = useState([]);

  // api call for get script
  const getScript = async () => {
    let respnose = await APICall(
      "get",
      `${EndPoints.getScriptList}${marketName}`
    );
    return respnose?.data?.data;
  };

  const { isLoading, data: scriptData } = useQuery({
    queryKey: ["scriptList", marketName],
    queryFn: getScript,
    enabled: marketName ? true : false,
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  // life cycle
  useEffect(() => {
    // get customized data from script data
    if (scriptData) {
      setScriptList(() => {
        return scriptData?.map((el, index) => ({
          value: el?.symbol,
          label: el?.symbol,
          key: index,
        }));
      });
    }
  }, [scriptData]);

  return {
    scriptLoading: isLoading,
    scriptList: scriptList,
  };
};

export const useEditUser = ({ item, params }) => {
  const queryClient = useQueryClient();
  const { isLoading: editUserLoading, mutate: editUserMutate } = useMutation({
    mutationKey: ["editUser"],
    mutationFn: async (data) => {
      let response = await APICall(
        "post",
        `${EndPoints.editUsers}${item?.userId}`,
        data
      );
      return response;
    },
    onSuccess: (response) => {
      if (response?.status === 200) {
        queryClient.refetchQueries({ queryKey: ["usersDetails", params] });
        notification.success({
          message: `Only Position Squareup of ${item?.userId}(${item?.accountName}) Updated Successfully`,
        });
      } else {
        notification.error({ message: response?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return { editUserLoading, editUserMutate };
};

export const useCalculateLedger = ({ id }) => {
  const queryClient = useQueryClient();
  const { isLoading: ledgerLoading, mutate: ledgerMutate } = useMutation({
    mutationKey: ["calculateLedger"],
    mutationFn: async (type) => {
      let url = `${EndPoints.recalculateLedger}?clientId=${id}`;
      if (type === "all") url += `&type=${type}`;
      const response = await APICall("get", url);
      return response;
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        queryClient.refetchQueries({ queryKey: ["getUserLedger"] });
        notification.success({ message: data?.data?.message });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
  });

  return { ledgerLoading, ledgerMutate };
};

export const useCalculateMargin = ({ id, query }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["calculateMargin", id],
    mutationFn: async (marketName) => {
      let url = `${EndPoints.calculateMargin}?userId=${id}`;
      if (marketName) url += `&marketName=${marketName}`;
      const response = await APICall("get", url);
      return response;
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        if (query) queryClient.refetchQueries({ queryKey: query });
      } else {
        notification.error({ message: data?.message });
      }
    },
  });

  return {
    marginLoading: mutation.status === "pending",
    marginMutate: mutation.mutate,
    updatedMargin: mutation.data,
  };
};

export const useCheckPassward = () => {
  // state
  const [flage, setFlage] = useState(false);

  // api call
  const {
    status,
    mutate: passwardMutate,
    data: passwardData,
  } = useMutation({
    mutationKey: ["checkPassward"],
    mutationFn: async (pass) => {
      let response = await APICall("post", EndPoints.passwordVerification, {
        password: pass,
      });
      return response;
    },
    onSuccess: (data) => {
      if (data.status === 200) {
        setFlage(true);
      } else {
        notification.error({ message: data?.data?.message });
        setFlage(false);
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    flage,
    passwardLoding: status === "pending",
    passwardMutate,
    setFlage,
    passwardData,
  };
};

export const useRevertAutoSquare = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["revertAutoSquare"],
    mutationFn: async (id) => {
      const response = await APICall(
        "delete",
        `${EndPoints.restoreAutoSquareOff}${id}`
      );
      return response;
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        queryClient.refetchQueries({ queryKey: ["autoSqure"] });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    revertLoading: mutation.status === "pending",
    revertData: mutation.data,
    revertMutate: mutation.mutate,
  };
};

export const useEditUserDetails = (id) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["editUserDetails", id],
    mutationFn: async (data) => {
      const response = await APICall(
        "post",
        `${EndPoints.editUsers}${id}`,
        data
      );
      return response;
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        queryClient.refetchQueries({ queryKey: ["allBlockedScript"] });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    editUserLoading: mutation.status === "pending",
    editUserData: mutation.data,
    editUserMutate: mutation.mutate,
  };
};

export const useUpdateCSV = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["updateCSVFile"],
    mutationFn: async () => {
      const response = await APICall("get", `${EndPoints.updateCSV}`);
      return response;
    },
    onSuccess: (data) => {
      if (data?.status === 200) {
        notification.success({ message: data?.data?.message });
        queryClient.refetchQueries({ queryKey: ["blockScript"] });
      } else {
        notification.error({ message: data?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  return {
    updateCSVLoading: mutation.status === "pending",
    updateCSVData: mutation.data,
    updateCSVMutate: mutation.mutate,
  };
};

export const useBlockScriptList = (userId) => {
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const query = useQuery({
    queryKey: ["allBlockedScript"],
    queryFn: async () => {
      let isAllow = filterUser?.includes(Number(userId));
      if (!isAllow) {
        notification.error({ message: "User Id is not valid" });
        return [];
      }
      let respnose = await APICall(
        "get",
        `${EndPoints.banScriptList}?userId=${userId}`
      );
      return respnose?.data?.data;
    },
    // onSuccess: (respnose) => {
    //   if (respnose?.status === 200) {
    //   } else {
    //     notification.error({ message: respnose?.data?.message });
    //   }
    // },
    // onError: (error) => {
    //   notification.error({ message: error?.message });
    // },
  });

  if (query.error) notification.error({ message: query.error?.message });

  return {
    allBlockScriptLoading: query?.isLoading,
    allBlockScriptData: query?.data,
    // allBlockScriptMutation: mutation?.mutate,
  };
};
