import React, { useEffect, useLayoutEffect, useRef } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Pagination, Radio, Row, theme } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";

const dataSource = [
  {
    key: 1,
    client: "1",
    oldAmount: "0.00",
    newAmount: "0.00",
    oldRemark: "0.00",
    newRemark: "0.00",
    logType: "0.00",
    ip: "0.00",
    editTime: "0.00",
    addTime: "0.00",
  },
  {
    key: 2,
    client: "1",
    oldAmount: "0.00",
    newAmount: "0.00",
    oldRemark: "0.00",
    newRemark: "0.00",
    logType: "0.00",
    ip: "0.00",
    editTime: "0.00",
    addTime: "0.00",
  },
  {
    key: 3,
    client: "1",
    oldAmount: "0.00",
    newAmount: "0.00",
    oldRemark: "0.00",
    newRemark: "0.00",
    logType: "0.00",
    ip: "0.00",
    editTime: "0.00",
    addTime: "0.00",
  },
  {
    key: 4,
    client: "1",
    oldAmount: "0.00",
    newAmount: "0.00",
    oldRemark: "0.00",
    newRemark: "0.00",
    logType: "0.00",
    ip: "0.00",
    editTime: "0.00",
    addTime: "0.00",
  },
  {
    key: 5,
    client: "1",
    oldAmount: "0.00",
    newAmount: "0.00",
    oldRemark: "0.00",
    newRemark: "0.00",
    logType: "0.00",
    ip: "0.00",
    editTime: "0.00",
    addTime: "0.00",
  },
  {
    key: 6,
    client: "1",
    oldAmount: "0.00",
    newAmount: "0.00",
    oldRemark: "0.00",
    newRemark: "0.00",
    logType: "0.00",
    ip: "0.00",
    editTime: "0.00",
    addTime: "0.00",
  },
];

function PointLedgerLog() {
  const valueRef = useRef({});
  const { setSearchParams, params } = useGetQueryParams();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);

  //left cycle
  useLayoutEffect(() => {
    setSearchParams({ userId: loginId });
  }, []);

  // api call
  const getPointLedgerLog = async () => {
    let response = await APICall(
      "get",
      `${EndPoints.getPointLedgerLog}?userId=${loginId}&page=${
        params?.page || 1
      }&limit=${params?.limit || 10}`
    );
    return response?.data?.data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["pointLedgerLog", params],
    queryFn: getPointLedgerLog,
    enabled: Object.keys(params)?.length > 0,
  });

  //function
  const handleSearch = () => {
    let value = valueRef.current;
    if (value) {
      setSearchParams({
        ...params,
        userId: value?.userId || loginId,
        page: 1,
      });
    }
  };

  //columns
  const columns = [
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "center",
    },
    {
      title: "OLD AMOUNT",
      dataIndex: "oldAmount",
      key: "oldAmount",
      align: "center",
    },
    {
      title: "NEW AMOUNT",
      dataIndex: "newAmount",
      key: "newAmount",
      align: "center",
    },
    {
      title: "OLD REMARK",
      dataIndex: "oldRemark",
      key: "oldRemark",
      align: "center",
    },
    {
      title: "NEW REMARK",
      dataIndex: "newRemark",
      key: "newRemark",
      align: "center",
    },
    {
      title: "LOG TYPE",
      dataIndex: "logType",
      key: "logType",
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      align: "center",
    },
    {
      title: "EDIT TIME",
      dataIndex: "editTime",
      key: "editTime",
      align: "center",
    },
    {
      title: "ADD TIME",
      dataIndex: "addTime",
      key: "addTime",
      align: "center",
    },
  ];
  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row gutter={[20, 20]} style={{ marginTop: "2rem" }}>
          <Col>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={dataSource}
        column={columns}
        headingStyle={true}
        filtertype="pointLedgerLog"
      />
      <Row align={"center"}>
        <Pagination current={params?.page || 1} />
      </Row>
    </>
  );
}

export default PointLedgerLog;
