import { useState } from "react";
import { Col, Popover, Row, Skeleton } from "antd";
import { labelObj } from "../../../../Helper/constant";
import { UplineTag } from "../style";
import { useGetUplineUsers } from "../../../../APICall/apiHooks";

const UPlineComponant = ({ record, index }) => {
  // state
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [userId, setUserId] = useState(null);

  // custom hook for upline api call
  const { upLineLoading, upLineData } = useGetUplineUsers({ id: userId });

  const isOpen = openPopoverIndex === index;

  // functions
  const content = (index) => {
    return (
      <div>
        {upLineLoading ? (
          <Skeleton active />
        ) : (
          upLineData?.map((el) => {
            return (
              <Row
                gutter={[10, 10]}
                style={{ marginBottom: "0.2rem" }}
                key={el?._id}
              >
                <Col span={6}>
                  <UplineTag color="magenta" bordered={false}>
                    {labelObj[el?.accountType]}
                  </UplineTag>
                </Col>

                <Col span={18}>{`: ${el?.accountName} (${el?.userId})`}</Col>
              </Row>
            );
          })
        )}
      </div>
    );
  };

  const handleOpenChange = async (index, record) => {
    setOpenPopoverIndex(index === openPopoverIndex ? null : index);
    if (index !== openPopoverIndex) {
      setUserId(record?.userId);
    }
  };

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <Popover
          content={() => content(index)}
          title="Upline List"
          trigger="click"
          placement="top"
          open={isOpen}
          onOpenChange={() => handleOpenChange(index, record)}
        >
          {`${record?.parent?.userId} (${record?.parent?.accountName})`}
        </Popover>
      </div>
    </>
  );
};

export default UPlineComponant;
