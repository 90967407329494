import { Col, Row, Spin, Switch, notification, theme } from "antd";
import React, { useState } from "react";
import useColorChange from "../../hooks/useColorChange";
import { useSelector } from "react-redux";
import ThemeCard from "../../components/ThemeCard";
import ThemeInput from "../../components/ThemeInput";
import ThemeDropDown from "../../components/ThemeDropDown";
import ActionButton from "../../components/ActionButton";
import { useEffect } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import {
  options,
  offlineOptions,
  StyledTheme,
  TradeModalRadio,
  TradeModalRadioGroup,
  TradeModalDivider,
} from "./style";
import { useGetFilteredUsers } from "../../APICall/apiHooks";
import { GlobleMarket } from "../../Helper/constant";

export const isValidTrade = (tradeObj, record) => {
  const isNSEEQU = record?.marketName === "NSE EQU";
  const isValidLot = isNSEEQU || tradeObj.lot > 0;
  const isValidQuantity = tradeObj?.quantity > 0;
  const isValidPrice = tradeObj?.tradePrice > 0;

  const isAllValid = isValidLot && isValidQuantity && isValidPrice;

  if (!isAllValid) {
    if (!isValidLot) {
      return [isAllValid, "Lot"];
    } else if (!isValidQuantity) {
      return [isAllValid, "Quantity"];
    } else if (!isValidPrice) {
      return [
        isAllValid,
        `Price (if you have open position in ${record?.symbolName} then, go to trades->position->exit to close position)`,
      ];
    }
  }

  return [isAllValid, null];
};

const ModalView = ({ modalType, record, onClose, activeScript }) => {
  const { marketName } = record;
  //state
  const [tradeType, setTradeType] = useState("market");
  const [type, setType] = useState(modalType === "BuyPrice" ? 1 : 2);
  const [tradeLoading, setTradeLoading] = useState(false);
  const [offlineTrade, setOfflineTrade] = useState(false);
  const [price, setPrice] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const [value, setValue] = useState({
    lot: 1,
    qty: record?.lotSize || 1,
  });
  const [preValue, setPreValue] = useState({ lot: 1, qty: record?.lotSize });
  const showLot = marketName === "NSE EQU" ? false : true;

  // redux
  const userDetails = useSelector((state) => state.authSlice);
  const data = useSelector(
    (state) => state?.webSocketSlice?.webSocket?.data?.[record?.symbol] || 0
  );
  const accountType = userDetails?.userDetail?.accountType;

  // custom hook for get filtered users
  const { filteredData, filterDataLoading } = useGetFilteredUsers({
    type: "customer",
    editable: accountType !== "customer",
  });

  // variable
  const lotSize = record?.lotSize || 1;
  const userId = userDetails?.userDetail?.userId;
  const userIP = userDetails?.user?.userIP || "";
  const allowofflineTrade = userDetails?.additionalSharing?.offlineTrade;
  const valid = GlobleMarket.includes(marketName);
  const market =
    marketName === "FOREX" || marketName === "GLOBEX" ? "forex" : "other";

  const createTrade = async (tradeObj) => {
    setTradeLoading(true);
    try {
      let response = await APICall("post", EndPoints.createTrade, tradeObj);
      if (response?.status === 200) {
        notification.success({ message: response?.data?.message });
        setTradeLoading(false);
        onClose();
        setPrice(0);
      } else {
        notification.error({ message: response?.data?.message, duration: 10 });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setTradeLoading(false);
    }
  };

  // life cycle
  useEffect(() => {
    if (accountType === "customer") {
      setCustomerId(userId);
    }
  }, []);

  useEffect(() => {
    if (modalType) {
      setType(modalType === "BuyPrice" ? 1 : 2);
    }
  }, [modalType]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "+") {
        setType(1);
      } else if (e.key === "-") {
        setType(2);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [type]);

  // function
  const handleSubmit = async () => {
    if (customerId === "") {
      return notification.error({ message: "Please fill CustomerId" });
    }
    if (!activeScript?.[record?.symbol] && !offlineTrade) {
      return notification.error({ message: "This script is not active" });
    }
    let marketCheck = marketName === "NSE EQU" || marketName === "NSE FUT";
    let limitTrade = tradeType === "limit" || tradeType === "intradaylimit";

    if (limitTrade && price >= data?.Ask && price <= data?.Bid) {
      return notification.error({
        message: "Limit order not allow between buy price and sell price",
      });
    }
    if (limitTrade && marketCheck && !offlineTrade) {
      let modifiedPrice = price?.split(".")?.[1];
      const isPriceAllow =
        modifiedPrice?.[1] == 0 ||
        modifiedPrice?.[1] == 5 ||
        modifiedPrice?.[1] == undefined;
      if (price > 250 && !isPriceAllow) {
        return notification.error({ message: "Price is not in Format" });
      }
    }

    let tradeObj = {
      tradeId: userId,
      customerId: customerId,
      tradeIpAddress: userIP || 0,
      marketName: marketName || null,
      symbolName: record?.symbolName || null,
      symbol: record?.symbol || null,
      expiryDate: record?.expiryDate || null,
      lot: marketName === "NSE EQU" ? 0 : Number(value.lot),
      lotSize: marketName === "NSE EQU" ? 1 : record?.lotSize,
      ltp: data?.LTP,
      quantity: Number(value.qty),
      title: tradeType,
      ordertype:
        tradeType === "market" || tradeType == "limit" ? "market" : "intraday",
      stoploss:
        marketName === "GLOBEX" || marketName === "FOREX"
          ? type == 1
            ? data?.Bid
            : data?.Ask
          : data?.LTP || 0,
      takeprofit: null,
      tradePattern: type === 1 ? "buy" : "sell",
      tradePrice:
        (tradeType === "market" || tradeType === "intraday") && !offlineTrade
          ? type == 1
            ? data?.Bid
              ? data?.Bid
              : 0
            : data?.Ask
            ? data?.Ask
            : 0
          : Number(price),
      high: data?.High,
      low: data?.Low,
      strikePrice: record?.strikePrice || null,
      series: record?.series || null,
      marketStatus: offlineTrade === true ? "offline" : "online",
      tradeTime: new Date(),
      deviceType: "web",
    };

    if (tradeObj?.stoploss == 0) {
      return notification.error({ message: "LTP/Quotation not found" });
    }

    const [allValid, name] = isValidTrade(tradeObj, record);
    if (!allValid) {
      notification.error({
        message: `Please enter positive numbers greater than 0 for ${name}`,
      });
    } else {
      createTrade(tradeObj);
    }
  };

  const onChangeTradeType = ({ target: { value } }) => {
    setTradeType(value);
    if (value === "limit" || value === "intradaylimit") {
      setPrice(0);
    } else {
      setPrice(
        type == 1 ? (data?.Bid ? data?.Bid : 0) : data?.Ask ? data?.Ask : 0
      );
    }
  };

  const onChange = ({ target: { value } }) => {
    setType(value);
  };

  const onChangeValue = ({ target: { name, value } }) => {
    if (name === "lot") {
      setValue({ lot: value, qty: lotSize * (value || 1) });
      setPreValue({ lot: value, qty: lotSize * (value || 1) });
    } else {
      setValue({ qty: value, lot: (value || 1) / lotSize });
      setPreValue({ qty: value, lot: (value || 1) / lotSize });
    }
  };

  const onChangePrice = ({ target: { value } }) => {
    const validMarket = GlobleMarket.includes(marketName);
    if (validMarket) {
      setPrice(value);
      return;
    }
    const validFormat = /^\d*\.?\d{0,2}$/.test(value);
    const isEmptyOrValid = validFormat || value === "";
    if (marketName === "MCX" && parseFloat(value) > 1500 && isEmptyOrValid) {
      setPrice(parseFloat(value));
      return;
    }
    if (isEmptyOrValid) {
      const decimal = value.split(".")[1]?.toString();
      const isNSEMarket = ["NSE EQU", "NSE FUT"].includes(marketName);
      const isValidDecimal =
        !decimal ||
        decimal.length === 1 ||
        (decimal.length === 2 &&
          (decimal[1] === "0" || decimal[1] === "5" || isNSEMarket));

      if (isValidDecimal) {
        setPrice(value);
      }
    }
  };

  // const onChangePrice1 = ({ target: { value } }) => {
  //   const regex = /^\d*\.?\d{0,2}$/;
  //   const isMCXOver1500 = marketName === "MCX" && parseFloat(value) > 1500;
  //   if ((regex.test(value) || value === "") && !valid) {
  //     const decimal = value.split(".")[1]?.toString();
  //     if (isMCXOver1500) {
  //       setPrice(parseFloat(value));
  //     } else {
  //       if (decimal?.length == 2) {
  //         const secondDecimalDigit = decimal[1];
  //         if (
  //           secondDecimalDigit === "0" ||
  //           secondDecimalDigit === "5" ||
  //           marketName === "NSE EQU" ||
  //           marketName === "NSE FUT" ||
  //           offlineTrade
  //         ) {
  //           setPrice(value);
  //         }
  //       } else if (!decimal || decimal?.length === 1) {
  //         setPrice(value);
  //       }
  //     }
  //   }
  //   if (valid) {
  //     setPrice(value);
  //   }
  // };

  const offlineTradeHandler = (item) => {
    setOfflineTrade(item);

    if (item) {
      if (tradeType === "limit" || tradeType === "intradaylimit") {
        setTradeType("market");
      }
      setPrice(
        type == 1 ? (data?.Bid ? data?.Bid : 0) : data?.Ask ? data?.Ask : 0
      );
    }
  };

  const onBlurPrice = () => {
    let checkPrice = Number(price);
    if (price === "" || !checkPrice) {
      setPrice(0);
    }
  };

  const onFocusPrice = () => {
    setPrice("");
  };

  const onChangeDropDown = (value) => {
    let stringValue = value[0];
    setCustomerId(stringValue);
  };

  const onBlur = ({ target: { name, value } }) => {
    if (!value) {
      setValue({
        lot: preValue.lot,
        qty: preValue.qty,
      });
    }
  };

  const onFocus = ({ target: { name, value } }) => {
    const numberValue = parseFloat(value.replace(/,/g, ""));
    setPreValue((pre) => ({ ...pre, [name]: numberValue }));
    setValue((pre) => ({ ...pre, [name]: null }));
  };

  return (
    <StyledTheme.Root>
      <StyledTheme.Modal
        open={true}
        onOk={onClose}
        onCancel={onClose}
        $type={type}
      >
        {accountType !== "customer" && allowofflineTrade && (
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              checkedChildren="Offline Trade"
              unCheckedChildren="Online Trade"
              onChange={offlineTradeHandler}
            />
          </Row>
        )}
        <Row
          justify={"space-evenly"}
          gutter={[5, 10]}
          style={{ marginTop: "1rem" }}
        >
          <Col xs={12} sm={8} md={6} lg={4} xl={3}>
            <ModalCellTrade
              title={`${record?.symbolName} ${record?.strikePrice || ""} ${
                record?.option_type || ""
              }  ${record?.expiryDate || ""}`}
              hideValue
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"SELL RATE"}
              currentValue={parseFloat(data?.Ask) || 0}
              checkUpdates
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"BUY RATE"}
              currentValue={parseFloat(data?.Bid) || 0}
              checkUpdates
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={market === "forex" ? "SPREAD" : "LTP"}
              currentValue={
                market === "forex" && data?.Spread
                  ? data?.Spread
                  : data?.LTP
                  ? data?.LTP
                  : 0
              }
              checkUpdates
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"VOLUME"}
              currentValue={
                data?.Volume == "0" ? "0" : data?.Volume ? data?.Volume : 0
              }
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"CHANGE %"}
              currentValue={`${data?.Price_Change || 0}%`}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"NET CHG"}
              currentValue={data?.Net_Change || 0}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade title={"HIGH"} currentValue={data?.High || 0} />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"LOW"}
              currentValue={parseFloat(data?.Low) || 0}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"OPEN"}
              currentValue={parseFloat(data?.Open) || 0}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"CLOSE"}
              currentValue={parseFloat(data?.Close) || 0}
            />
          </Col>
        </Row>
        <TradeModalDivider />
        <ThemeCard bordered={false} style={{ margin: "1rem 0rem" }}>
          <StyledTheme.BuySellWrapper gutter={[5, 10]} justify={"space-evenly"}>
            <StyledTheme.RadioDiv>
              <Col xs={24} sm={12} md={12} lg={12} xl={7}>
                <TradeModalRadioGroup value={type} onChange={onChange}>
                  <TradeModalRadio value={1}>Buy</TradeModalRadio>
                  <TradeModalRadio value={2}>Sell</TradeModalRadio>
                </TradeModalRadioGroup>
              </Col>
              <Col span={24}>
                <StyledTheme.MarketTypeRadio
                  options={offlineTrade ? offlineOptions : options}
                  onChange={onChangeTradeType}
                  value={tradeType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
            </StyledTheme.RadioDiv>

            {showLot && (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={4}
                style={{ marginTop: "1rem" }}
              >
                <ThemeInputNumber
                  marginBottom={"0rem"}
                  width={"100%"}
                  title="LOT"
                  extraProps={{
                    input_type: "number",
                  }}
                  inputProps={{
                    placeholder: "LOT",
                    name: "lot",
                    value: value?.lot,
                    onChange: onChangeValue,
                    onBlur: onBlur,
                    onFocus: onFocus,
                  }}
                />
              </Col>
            )}
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              style={{ marginTop: "1rem" }}
            >
              <ThemeInputNumber
                marginBottom={"0rem"}
                width={"100%"}
                title="QTY"
                className="ThemeInput"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  placeholder: "QTY",
                  name: "qty",
                  value: value?.qty,
                  onChange: onChangeValue,
                  onBlur: onBlur,
                  onFocus: onFocus,
                  // disabled: record?.market !== "NSE FUT",
                  locked: true,
                }}
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              style={{ marginTop: "1rem" }}
            >
              <ThemeInput
                style={{ fontWeight: "bold" }}
                marginBottom={"0rem"}
                width={"100%"}
                title="PRICE"
                className="ThemeInput"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  onChange: onChangePrice,
                  onBlur: onBlurPrice,
                  onFocus: onFocusPrice,
                  // locked: !isPriceChangeAllow,
                  readOnly:
                    (tradeType === "market" || tradeType === "intraday") &&
                    !offlineTrade
                      ? "readOnly"
                      : null,
                  placeholder: "Price",
                  value:
                    (tradeType === "market" || tradeType === "intraday") &&
                    !offlineTrade
                      ? type == 1
                        ? data?.Bid
                          ? data?.Bid
                          : 0
                        : data?.Ask
                        ? data?.Ask
                        : 0
                      : price,
                }}
              />
            </Col>
            {accountType !== "customer" && (
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <ThemeDropDown
                  // marginBottom={"0rem"}
                  width={"100%"}
                  title={"Client"}
                  showSearch={true}
                  placeholder="Select Client"
                  options={filteredData}
                  value={customerId}
                  onChange={onChangeDropDown}
                  suffixIcon={
                    <Spin size="small" spinning={filterDataLoading} />
                  }
                />
              </Col>
            )}
          </StyledTheme.BuySellWrapper>

          <Row justify={"center"}>
            <ActionButton
              title={"Submit"}
              onClick={handleSubmit}
              loading={tradeLoading}
            />

            <ActionButton
              title={"Cancel"}
              buttonType="cancel"
              onClick={onClose}
            />
          </Row>
        </ThemeCard>
      </StyledTheme.Modal>
    </StyledTheme.Root>
  );
};

export default ModalView;

const ModalCellTrade = React.memo(
  ({
    name = "",
    title,
    currentValue,
    checkUpdates = false,
    hideValue = false,
  }) => {
    const token = theme.useToken().token;
    const { color, backgroundColor } = useColorChange({
      currentValue: checkUpdates ? currentValue : null,
      defaultBgColor: token.colorBgBase,
      defaultColor: token.colorTextLabel,
    });

    return (
      <StyledTheme.Cell style={{ backgroundColor, border: "1px solid white" }}>
        <div className="cell-title" style={{ color }}>
          {title}
        </div>
        {hideValue ? null : (
          <div className="cell-number" style={{ color }}>
            {currentValue || "~"}
          </div>
        )}
      </StyledTheme.Cell>
    );
  }
);
