import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Pagination, Row, notification } from "antd";
import GetDate from "../../components/DatePicker/GetDate";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { StyledButton } from "../../styles/commonStyle";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useQuery } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { useDispatch, useSelector } from "react-redux";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { setUserClearFilter } from "../../store/slice/globleSlice";

const IPAddressReport = () => {
  const valueRef = useRef({});
  const [totalCount, setTotalCount] = useState(1);
  const { params, setSearchParams } = useGetQueryParams();
  const dispatch = useDispatch();

  //life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginUserId,
    });
  }, []);

  //redux
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );

  //api call
  const getIPAddressReport = async () => {
    const payload = {
      userId: Number(params?.userId) || loginUserId,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
    };
    const response = await APICall(
      "post",
      `${EndPoints?.usersIpAddress}?page=${params?.page || 1}&limit=${
        params?.limit || 10
      }`,
      payload
    );
    setTotalCount(response?.data?.data?.pagination?.totalItems);
    return response.data?.data?.reports;
  };

  const {
    isLoading,
    data: ipAddressData,
    error,
  } = useQuery({
    queryKey: ["IpAddress", params],
    queryFn: getIPAddressReport,
    enabled: Object.keys(params)?.length > 0,
  });

  if (error) notification.error({ message: error?.message });

  //function
  const handleReset = () => {
    valueRef.current = {};
    dispatch(setUserClearFilter(true));
    setSearchParams({ userId: loginUserId });
  };
  const handleSubmit = () => {
    const searchData = valueRef.current;
    let paramObj = {
      userId: searchData?.userId || loginUserId,
      startDate: searchData?.startDate?.toISOString() || null,
      endDate: searchData?.endDate?.toISOString() || null,
      page: 1,
    };
    for (let key in paramObj) {
      if (!paramObj[key]) {
        delete paramObj[key];
      }
    }
    setSearchParams(paramObj);
  };

  const onChagePage = (page) => {
    setSearchParams({ ...params, page: page });
  };

  //column
  const columns = [
    {
      title: "Device",
      dataIndex: "deviceType",
      key: "device",
      align: "left",
      width: "4rem",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        return (
          <div>{`${record?.userId?.userId} (${record?.userId?.accountName})`}</div>
        );
      },
    },
    {
      title: "Login Time",
      dataIndex: "loginTime",
      key: "loginTime",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        const time = valanBillDate(record?.loginTime, true);
        return <div>{time ? time : "-"}</div>;
      },
    },
    {
      title: "Logout Time",
      dataIndex: "logout_time",
      key: "logout_time",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        const time = valanBillDate(record?.logoutTime, true);
        return <div>{time ? time : "-"}</div>;
      },
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
      align: "left",
      width: "14rem",
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[10, 15]}>
          <GetDate valueRef={valueRef} />
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row gutter={[10, 15]} style={{ marginTop: "2rem" }} justify="start">
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleSubmit}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleReset}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={ipAddressData}
        column={columns}
        // headingStyle={false}
        // isFilterTrue={true}
        isLoading={isLoading}
        filtertype="ipAddress"
      />
      <Row align={"center"}>
        <Pagination
          current={params?.page || 1}
          total={totalCount}
          pageSize={params?.limit || 10}
          onChange={onChagePage}
          showSizeChanger={false}
        />
      </Row>
    </>
  );
};

export default IPAddressReport;
