import { Col, Collapse, Row, Spin, Tabs, notification } from "antd";
import React, { useRef } from "react";
import ThemeDropDown from "../../components/ThemeDropDown";
import { SEGMENTS } from "../../Helper/constant";
import { useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useEffect } from "react";
import ActionButton from "../../components/ActionButton";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  resetExpirySetting,
  setExprirySetting,
  setTabChange,
  setUserIdInExpirySetting,
} from "../../store/slice/expirySettingSlice";
import { Container, SpinnerOverlay } from "../MarketSetting/MarketSetting";
import { StyledTabs, tabView } from "./style";
import NseFutureRender from "./NseFutureRender";
import NseOptionRender from "./NseOptionRender";
import NseEquRender from "./NseEquRender";

const { Panel } = Collapse;
const { TabPane } = Tabs;

export const items = [
  {
    key: "NSE FUT",
    label: "NSE FUTURE",
    children: <NseFutureRender tab="NSE FUT" />,
  },
  {
    key: "NSE OPT",
    label: "NSE-OPTION",
    children: <NseOptionRender tab="NSE OPT" />,
  },
  {
    key: "NSE EQU",
    label: "NSE-EQU",
    children: <NseEquRender tab="NSE EQU" />,
  },
  {
    key: "MCX",
    label: "MCX",
    children: <NseOptionRender tab="MCX" />,
  },
  {
    key: "MCX OPT",
    label: "MCX-OPTION",
    children: <NseOptionRender tab="MCX OPT" />,
  },
  {
    key: "COMEX",
    label: "COMEX",
    children: <NseOptionRender tab="COMEX" />,
  },
  {
    key: "FOREX",
    label: "FOREX",
    children: <NseEquRender tab="FOREX" />,
  },
  {
    key: "GLOBEX",
    label: "GLOBEX",
    children: <NseEquRender tab="GLOBEX" />,
  },
  // {
  //   key: "CRYPTO",
  //   label: "CRYPTO",
  //   children: <NseEquRender tab="CRYPTO" />,
  // },
];

const ExpirySetting = () => {
  // state
  const [masterOption, setMasterOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [value, setValue] = useState("");
  const canCallRef = useRef(true);
  const dispatch = useDispatch();

  // redux
  const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state?.expirySettingSlice?.[tab]);
  const userId = expiryValue?.userId;
  const view = tabView(tab);

  // api call
  const getExpirySetting = async () => {
    // if (!canCallRef.current) {
    //   return null;
    // }
    // canCallRef.current = false;
    setGetLoading(true);
    let URL = view
      ? `${EndPoints.getExpirySetting}?userId=${value}&&marketName=${SEGMENTS[tab]}`
      : `${EndPoints.getExpirySetting}?userId=${value}&&marketName=${
          SEGMENTS[tab]
        }&&scriptName=${encodeURIComponent(expiryValue?.scriptName)}`;
    try {
      let response = await APICall("get", URL);
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setGetLoading(false);
      // setTimeout(() => {
      //   canCallRef.current = true;
      // }, 300);
    }
  };

  const getUsers = async () => {
    let res = await APICall("get", `${EndPoints.getAllUser}?role=masterAdmin`);
    return res.data.data.userData;
  };

  const {
    isLoading: userLoading,
    data: userData,
    error: userError,
  } = useQuery({
    queryKey: ["usersDetails"],
    queryFn: getUsers,
    config: {
      background: true,
    },
  });

  if (userError) {
    notification.error({ message: userError?.message });
  }

  const handleSubmit = async () => {
    let obj = { ...expiryValue, userId: value };

    if (view && !value) {
      return notification.error({
        message: "Please select master admin before submit",
      });
    }
    if (!view && (!expiryValue.scriptName || !value)) {
      return notification.error({
        message: `Please fill details of ${
          !value ? "MasterAdmin" : "ScriptName"
        } before submit`,
      });
    }

    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.addExpirySetting, obj);
      if (response.status === 200) {
        // console.log("expiryResponse", response);
        notification.success({ message: response?.data?.message });
        // dispatch(resetExpirySetting());
        // setValue("");
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // life cycle
  useEffect(() => {
    return () => {
      dispatch(resetExpirySetting());
    };
  }, []);

  useEffect(() => {
    if (view && value) {
      getExpirySetting();
    }
  }, [tab, value]);

  useEffect(() => {
    if (!view && expiryValue?.scriptName && value) {
      getExpirySetting();
    }
  }, [expiryValue?.scriptName, value]);

  useEffect(() => {
    if (userData) {
      const masterAdminList = userData?.map((el) => {
        return {
          label: `${el?.accountName} (${el?.userId})`,
          value: el?.userId,
          id: el?._id,
        };
      });
      setMasterOption(masterAdminList);
    }
  }, [userData]);

  // function
  const onCancel = () => {
    console.log("cancle");
  };

  const onChange = (value) => {
    // console.log("change", value);
    dispatch(setTabChange(value));
  };

  const onChangeDropDown = (name, value, option) => {
    setValue(value[0]);
    // console.log("values", value[0]);
    dispatch(setUserIdInExpirySetting(value[0]));
  };

  const renderTabsInCollapse = () => {
    return (
      <Collapse>
        <Panel header="Tabs" key="1">
          <Tabs defaultActiveKey="1" onChange={onChange}>
            {items.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
        </Panel>
      </Collapse>
    );
  };

  return (
    <Container isloading={getLoading.toString()}>
      {getLoading && (
        <SpinnerOverlay>
          <Spin size="large" />
        </SpinnerOverlay>
      )}
      <div>
        <Col
          xs={24}
          sm={12}
          md={8}
          lg={6}
          xl={4}
          style={{ marginLeft: "1rem" }}
        >
          <ThemeDropDown
            title="MasterAdmin"
            placeholder="Select MasterAdmin"
            name="masterAdmin"
            options={masterOption}
            showSearch={true}
            width={"98%"}
            $onChange={onChangeDropDown}
            $value={value}
            loadings={userLoading ? "true" : "false"}
            suffixIcon={<Spin size="small" spinning={userLoading} />}
          />
        </Col>

        <StyledTabs
          defaultActiveKey="NSE FUT"
          items={items}
          onChange={onChange}
        />
        <Row justify={"center"}>
          <ActionButton
            title={"Submit"}
            loading={loading}
            onClick={handleSubmit}
          />
        </Row>
      </div>
    </Container>
  );
};

export default ExpirySetting;
