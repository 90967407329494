import React from "react";
import { Typography, Alert, Space } from "antd";
import { WifiOutlined } from "@ant-design/icons";
import styled from "styled-components";
const { Title, Text } = Typography;

const ModalOverlay = styled.div`
  position: fixed;
  top: 107px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 10%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;
const ModalTitle = styled.div`
  margin-top: 0;
  margin-bottom: 16px;
`;
const ModalContent = styled.div`
  margin-bottom: 16px;
`;

const DisconnectedModal = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <ModalTitle>
            <Title
              level={3}
              style={{ margin: 0, display: "flex", alignItems: "center" }}
            >
              <WifiOutlined style={{ marginRight: 8, color: "#ff4d4f" }} />
              Connection Lost
            </Title>
          </ModalTitle>
          <ModalContent>
            <Alert
              message="You are currently offline"
              description="Connection lost. This may be due to internet or network issues, or other possible factors."
              type="error"
              showIcon
            />
          </ModalContent>
          <Text type="secondary">
            Please check your network settings and try to reconnect. If the
            problem persists, it may be due to server issues or other external
            factors.
          </Text>
        </Space>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default React.memo(DisconnectedModal);
