import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "NSE FUT": {},
  "NSE OPT": {},
  "NSE EQU": {},
  MCX: {},
  "MCX OPT": {},
  FOREX: {},
  COMEX: {},
  GLOBEX: {},
  // CRYPTO: {},
  search: "",
};

export const scriptSettingSlice = createSlice({
  name: "scriptSettingSlice",
  initialState,
  reducers: {
    setScript: (state, action) => {
      const { tab, value, symbol, key } = action.payload;

      if (!state[tab][symbol] && symbol) {
        state[tab][symbol] = {};
      }
      if (!state[tab][symbol][key] && symbol) {
        state[tab][symbol][key] = {};
      }

      if (key === "isBlocked") {
        state[tab][symbol] = { [key]: value };
      } else {
        state[tab][symbol][key] = value;
      }
    },
    resetScript: () => ({ ...initialState }),
    deleteScript: (state, action) => {
      const { tab, symbol } = action.payload;
      if (state[tab][symbol]) {
        delete state[tab][symbol];
      }
    },
    updateScriptSlice: (state, action) => {
      const obj = action.payload;
      for (let key in obj) {
        state[key] = obj[key];
      }
    },
    setSearchScript: (state, action) => {
      state.search = action.payload;
    },
    setBlockScript: (state, action) => {
      const { tab, scriptList, block } = action.payload;
      scriptList?.forEach((el) => {
        if (!state[tab][el]) {
          state[tab][el] = {};
          state[tab][el] = { isBlocked: block ? true : false };
        } else {
          state[tab][el] = { isBlocked: block ? true : false };
        }
      });
    },
  },
});

export const {
  setScript,
  resetScript,
  deleteScript,
  updateScriptSlice,
  setSearchScript,
  setBlockScript,
} = scriptSettingSlice.actions;
export default scriptSettingSlice.reducer;
