import { ExclamationOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import styled from "styled-components";

export const StyledThemeInput = {
  Modal: styled(Modal)`
    & .ant-modal-content {
      background-color: ${(props) => props.theme.token.colorBgLayout};
    }
    .ant-modal-header {
      background-color: ${(props) => props.theme.token.colorBgLayout};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-title {
      width: fit-content;
      color: ${(props) => props.theme.token.colorPrimary};
      font-weight: 500;
      font-size: 1.3rem;
      margin-block: 1rem;
    }
  `,
};
export const FlexContainer = styled.div`
  display: flex;
`;
export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isActive
      ? props.theme.token.colorPrimary
      : props.isChecked
      ? props.theme.token.colorPrimaryBgHover
      : "white"};
  border-radius: 10px;
  padding: 1.5rem;
  border: ${(props) =>
    props.isValid
      ? "3px solid #b93d3d"
      : `1px solid ${props.theme.token.colorBorder}`};
  position: relative;
  cursor: pointer;
`;
export const TabLabel = styled.span`
  margin-inline: 0.5rem;
  color: ${(props) =>
    props.isActive
      ? props.theme.token.colorTextLightSolid
      : props.isChecked
      ? "white"
      : props.theme.token.colorText};
  font-size: 1rem;
  font-weight: 600;
`;
export const StyledExclamationOutlined = styled(ExclamationOutlined)`
  color: red;
  font-size: 1.5rem;
`;
export const StyleDiv = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;
export const RowDiv = styled(Row)`
  width: 100%;
`;
export const RightRow = styled(Row)`
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const LeftRow = styled(Row)`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const Accounts = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: auto;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
export const AccountDetails = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid red; */
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 3rem;
  }
`;
export const Main = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const ProfilCol = styled(Col)`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 769px) {
    display: none;
  }
`;
export const StyledTheme = {
  Wrapper: styled(Space)`
    justify-content: space-between;
    & .ant-tabs-ink-bar {
      height: 0 !important;
    }
    &.ant-space {
      width: 100%;
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-tabs-nav-list {
      width: 100%;
      justify-content: space-between;
    }
  `,
  Checkbox: styled(Checkbox)`
    & .ant-checkbox-inner {
      /* border: none;
      background-color: ${(props) =>
        `${props.theme.token.Checkbox.colorBgLightGrey} !important`};
      border-color: ${(props) =>
        `${props.theme.token.Checkbox.colorBgLightGrey} !important`}; */
    }

    .ant-checkbox-inner::after {
      ${"" /* background-color: #add8e6; */}/* border-color: ${(props) =>
        props?.checked
          ? props.theme.token.colorBgActionButton
          : props.theme.token.colorTextLightSolid}; */
    }
  `,
  TabPaneWrapper: styled(Space)`
    width: 100%;
    height: ${(props) => (props.show ? "fit-content" : "0rem")};
    min-height: ${(props) => (props.show ? "0rem" : "0rem")};
    max-height: ${(props) => (props.show ? "150rem" : "0rem")};
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 400ms ease-in-out;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    & .ant-space-item {
      width: 100%;
    }
  `,
  TabDiv: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.token?.colorPrimaryBgHover};
  `,
};
export const StyleSpan = styled(Space)`
  display: flex;
  text-align: left;
`;
export const AddButton = styled(Button)`
  padding: 2px 7px;
  border: 1px solid
    ${({ isAdd, token }) =>
      isAdd ? token.marketWatchUp : token.marketWatchDown};
  border-top-right-radius: ${({ isAdd }) => (isAdd ? "unset" : "6px")};
  border-bottom-right-radius: ${({ isAdd }) => (isAdd ? "unset" : "6px")};
  border-top-left-radius: ${({ isAdd }) => (isAdd ? "6px" : "unset")};
  border-bottom-left-radius: ${({ isAdd }) => (isAdd ? "6px" : "unset")};
  background: ${({ isAdd, token }) =>
    isAdd ? token.marketWatchUp : token.marketWatchDown};
  color: white;
  height: 100% !important;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
  margin-right: 1.7rem;
  z-index: 1000;
`;
export const PopupFooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
export const PopupOkButton = styled(Button)`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
export const BottomRow = styled(Row)`
  display: flex;
  justify-content: right;
  width: 100%;
`;
export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  width: 250px;
`;
export const StyledText = styled(Typography.Text)`
  cursor: pointer;
  display: flex;
  justify-content: end;
  color: ${(props) => props.theme.token.blue};
`;
export const StyledFormItem = styled(Form.Item)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
`;
export const StyledSpace = styled(Space)`
  width: 100%;
`;
export const StyledForm = styled(Form)`
  height: fit-content;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export const ContentFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;
export const AdditionalInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
`;
export const AdditionalInfoModalCard = styled(Col)`
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  align-items: center;
  color: ${(props) => props.theme.token.colorPrimary};
`;
export const DefaultUserCol = styled(Col)`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
`;
export const ScriptWiseSettingCheckbox = styled(Checkbox)`
  font-size: 1rem !important;
  font-weight: 400 !important;
`;
export const AccountOpeningViewCol = styled(Col)`
  display: flex;
  justify-content: start;
  margin-bottom: 1rem;
`;
export const AdditionalInfoItemRow = styled(Row)`
  margin-bottom: 1rem;
  font-weight: bold;
`;
export const CopyButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 2rem;
  text-align: center;
`;
export const BrokerageDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 1.5rem;
`;
export const EqualToCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: end;

  @media (min-width: 575px) {
    /* margin-top: {
      ${(props) => (props?.type === "margin" ? "0rem" : "1.7rem")}
    } */
    margin-bottom: 0.5rem;
  }
`;
export const MarginCol = styled(Col)`
  display: flex;
  justify-content: center;

  @media (min-width: 575px) {
    margin-top: 2.4rem;
  }
`;
export const MarginRow = styled(Row)`
  display: flex;
  /* align-items: center; */
  /* align-content: center; */
  justify-content: ${({ isCustomer }) =>
    isCustomer ? "space-between" : "center"};

  @media (max-width: 1200px) {
    justify-content: ${({ isCustomer }) =>
      isCustomer ? "center" : "space-between"} !important;
  }
`;

export const marginValue = [
  {
    label: "Value",
    value: "value",
  },
];
