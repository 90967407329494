import React, { useEffect, useState } from "react";
import ThemeCard from "../../../components/ThemeCard";
import { useNavigate, useParams } from "react-router-dom";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import { CalculatorOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  ConvertAccountName,
  compareAccount,
  labelObj,
} from "../../../Helper/constant";
import { formatToINR } from "../../../components/FormateToINR/formatToINR";
import { StyledTable } from "../../Profile/style";
import { StyledButton } from "../../../styles/commonStyle";
import { UplineTag, accountColumns, brokerageColumns } from "./style";
import {
  useCalculateLedger,
  useCalculateMargin,
  useGetChildUsers,
  useGetUplineUsers,
} from "../../../APICall/apiHooks";
import { CapitalFirstLetter } from "../../../components/CommanFuction/CommanFunction";

const { Text, Title } = Typography;

const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // custom hook for child user api call
  const { childUsers, isLoading: childLoading } = useGetChildUsers({ id });
  // custom hook for upline api call
  const { upLineData } = useGetUplineUsers({ id });
  // custom hook for ledger api call
  const { ledgerLoading, ledgerMutate } = useCalculateLedger({ id });
  // custom hook for margin api call
  const { marginLoading, marginMutate } = useCalculateMargin({
    id,
    query: ["userProfile"],
  });

  //state
  const [accountsData, setAccountsData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [showModal, setShowModal] = useState({ flag: false, data: null });

  // user details api
  const UserDetails = async () => {
    let response = await APICall("get", `${EndPoints.getUser}${id}`);
    return response?.data?.data;
  };
  const {
    isLoading: userLoading,
    error: userError,
    data: userData,
  } = useQuery({
    queryKey: ["userProfile"],
    queryFn: UserDetails,
    refetchOnMount: true,
  });

  // life cycle
  useEffect(() => {
    if (childUsers) {
      const obj = childUsers?.reduce((acc, child) => {
        acc[child.accountType] = [...(acc[child.accountType] || []), child];
        return acc;
      }, {});
      setChildData(obj);
    }
  }, [childUsers]);
  useEffect(() => {
    if (userData) {
      setAccountsData(() => {
        return Object.keys?.(userData?.accountDetails)
          ?.filter((el) => compareAccount[el])
          ?.map((el) => ({
            remaining: userData?.accountDetails?.[el],
            name: ConvertAccountName[el],
            total:
              userData?.accountDetails?.totalAccounts?.[0]?.[
                compareAccount[el]
              ],
          }));
      });
    }
  }, [userData]);

  // function
  const recalculateMargin = () => {
    marginMutate();
  };
  const recalculateLedger = () => {
    ledgerMutate();
  };

  const content = (data) => {
    return (
      <div style={{ padding: "0.5rem", maxHeight: "250px", overflow: "auto" }}>
        {childLoading ? (
          <Skeleton active />
        ) : (
          data?.map((el) => {
            return (
              <Row
                gutter={[10, 10]}
                style={{ marginBottom: "0.2rem" }}
                key={el?._id}
              >
                <Col span={6}>
                  <UplineTag color="magenta" bordered={false}>
                    {labelObj[el?.accountType]}
                  </UplineTag>
                </Col>

                <Col span={18}>{`: ${el?.accountName} (${el?.userId})`}</Col>
              </Row>
            );
          })
        )}
      </div>
    );
  };

  const handleLedgerBalance = () => {
    navigate(`/user-ledger/${id}`);
  };

  //column
  const marginColumns = [
    {
      title: "Market",
      key: "market",
      dataIndex: "market",
      //   width: 100,
      render: (text, record) => <div>{record?.marketAccessId?.scriptName}</div>,
    },
    {
      title: "Delivery",
      key: "delivery",
      dataIndex: "delivery",
      //   width: 100,
      render: (text, record) => (
        <span>
          {`${formatToINR(record?.delivery)}
            /${formatToINR(record?.history?.[0]?.delivery)} `}
        </span>
      ),
    },
    {
      title: "Intraday",
      key: "intraday",
      dataIndex: "intraday",
      //   width: 100,
      render: (text, record) => (
        <span>
          {`${formatToINR(record?.intraday)}
        /${formatToINR(record?.history?.[0]?.intraday)} `}
        </span>
      ),
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      align: "center",
      //   width: 100,
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            <div>{formatToINR(record?.total)}</div>
            <div style={{ marginLeft: "auto" }}>
              <Tooltip title="Re-Calculate Margin">
                <Popconfirm
                  title={`Re-Calculate ${record?.marketAccessId?.scriptName} margin?`}
                  description={`Are you sure to re-calculate margin for ${record?.marketAccessId?.scriptName}?`}
                  onConfirm={() =>
                    marginMutate(record?.marketAccessId?.scriptName)
                  }
                >
                  <Button
                    className="recalculate-button"
                    // onClick={() =>
                    //   marginMutate(record?.marketAccessId?.scriptName)
                    // }
                  >
                    <CalculatorOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[5, 10]}>
          {upLineData?.map((el, index) => {
            return (
              <Col key={el?._id}>
                {`${el?.accountName} (${el?.userId})`}{" "}
                <Tag color="magenta" bordered={false}>
                  {labelObj[el?.accountType]}
                </Tag>
                {upLineData?.length - 1 !== index && <RightOutlined />}
              </Col>
            );
          })}
        </Row>
        <Row gutter={[0, 10]} style={{ marginTop: "1rem", gap: "1rem" }}>
          {childData &&
            Object?.entries(childData)?.map(([key, value]) => {
              return (
                <Col
                  key={key}
                  align={"middle"}
                  span={2}
                  onClick={() =>
                    setShowModal({ flag: true, data: { key, value } })
                  }
                  className="user-profile-totalChilds"
                >
                  <Tag
                    color="magenta"
                    bordered={false}
                    className="total-childTag"
                  >
                    <p>{CapitalFirstLetter(key)}</p>
                  </Tag>
                  <p className="total-child-number"> {value?.length}</p>
                </Col>
              );
            })}
        </Row>
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ marginTop: "1rem" }}
        >
          <Col>
            <text className="user-profile-name">
              {userData?.user?.accountName} {`(${id})`}
              <Tag color="magenta" bordered={false}>
                {labelObj[userData?.user?.accountType]}
              </Tag>
            </text>
          </Col>
          <Col className="user-profile-balance">
            {`M2M Balance: ${formatToINR(
              userData?.user?.balance
            )}/${formatToINR(userData?.user?.history?.[0]?.balance)}`}
          </Col>
          <Col className="user-profile-balance" onClick={handleLedgerBalance}>
            {`Ledger Balance: ${formatToINR(userData?.user?.ledgerBalance)}`}
          </Col>
          <Col>
            <Tooltip title="Re-Calculate Ledger">
              <Popconfirm
                title="Re-Calculate Ledger"
                onConfirm={recalculateLedger}
                placement="leftTop"
                description="Are you sure want to recalculate ledger?"
              >
                <StyledButton
                  type="primary"
                  // onClick={recalculateLedger}
                  loading={ledgerLoading}
                >
                  <CalculatorOutlined />
                  Re-Ledger
                </StyledButton>
              </Popconfirm>
            </Tooltip>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard style={{ marginTop: "1rem" }}>
        <Row
          justify={"space-around"}
          gutter={[10, 10]}
          style={{ textAlign: "center" }}
        >
          {(userData?.margin?.length > 0 || userLoading) && (
            <Col xs={24} sm={24} md={24} lg={16} xl={9}>
              <Title level={5}>Margin</Title>
              <StyledTable
                columns={marginColumns}
                dataSource={userData?.margin}
                pagination={false}
                loading={userLoading}
              />
              <Popconfirm
                title={"Re-Calculate Margin"}
                description={
                  "Are you sure to re-calculate margin of all markets?"
                }
                onConfirm={recalculateMargin}
                placement="bottom"
              >
                <StyledButton
                  // onClick={recalculateMargin}
                  loading={marginLoading}
                >
                  Re-calculate Margin
                </StyledButton>
              </Popconfirm>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={16} xl={9}>
            <Title level={5}>Brokerage</Title>
            <StyledTable
              columns={brokerageColumns}
              dataSource={userData?.sharingBrokerageSchema}
              pagination={false}
              loading={userLoading}
            />
            <StyledButton>Re-calculate Brokerage</StyledButton>
          </Col>
          {userData?.user?.accountType !== "broker" &&
            userData?.user?.accountType !== "customer" && (
              <Col xs={24} sm={24} md={24} lg={16} xl={9}>
                <Title level={5}>Allow No. of Accounts</Title>
                <StyledTable
                  columns={accountColumns}
                  dataSource={accountsData}
                  pagination={false}
                  loading={userLoading}
                />
              </Col>
            )}
        </Row>
      </ThemeCard>
      {showModal.flag && (
        <Modal
          title={`${userData?.user?.accountName} (${id}) > ${CapitalFirstLetter(
            showModal?.data?.key
          )} List`}
          open={showModal.flag}
          onCancel={() => setShowModal({ flag: false })}
          footer={null}
        >
          <div className="chid-list-modal-div">
            {childLoading ? (
              <Skeleton active />
            ) : (
              showModal?.data?.value?.map((el) => {
                return (
                  <Row
                    gutter={[10, 10]}
                    style={{ marginBottom: "0.2rem" }}
                    key={el?._id}
                  >
                    <Col span={3}>
                      <UplineTag color="magenta" bordered={false}>
                        {labelObj[el?.accountType]}
                      </UplineTag>
                    </Col>

                    <Col
                      span={18}
                    >{`: ${el?.accountName} (${el?.userId})`}</Col>
                  </Row>
                );
              })
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserProfile;
