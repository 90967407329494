import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Switch,
  Typography,
  notification,
} from "antd";
import React, { useEffect } from "react";
import ThemeInput from "../../components/ThemeInput";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeTimePicker from "../../components/ThemeTimePicker";
import { useState } from "react";
import dayjs from "dayjs";
import "./marketSetting.css";
import { MarketCartRow, StyleButton, StyledTheme } from "./style";

const MarketCart = ({ market, index, getMarketStatus, values }) => {
  // state
  const [disable, setDisable] = useState({
    "NSE FUT": true,
    "NSE OPT": true,
    "NSE EQU": true,
    MCX: true,
    "MCX OPT": true,
    FOREX: true,
    COMEX: true,
    GLOBEX: true,
  });
  const [value, setValue] = useState({});
  const [show, setShow] = useState({
    open: false,
    market: null,
  });

  // life cycle
  useEffect(() => {
    if (values) {
      setValue({ ...values });
    }
  }, [values]);

  // function
  const onChange = (status, market) => {
    const status1 = status ? "online" : "offline";
    setValue({
      ...value,
      status: status1,
    });
  };

  const handleEdit = (value) => {
    setDisable({ ...disable, [value]: !disable[value] });
    setShow({ open: false, market: null });
  };

  const handleChangeTime = (e) => {
    const { name, value } = e.target;
    setValue((pre) => {
      return { ...pre, [name]: dayjs(value) };
    });
  };

  const handleSave = (market) => {
    setShow({ open: true, market: market });
  };

  const handleCancel = () => {
    setShow({ open: false, market: null });
  };
  const onChangeLimit = (value, market) => {
    setValue((pre) => ({ ...pre, isLimitTradeAllowed: value }));
  };
  return (
    <>
      <MarketCartRow gutter={[16, 16]} key={index}>
        <Col xs={12} sm={4} md={4} lg={2} className="market-title">
          <StyledTheme.Title>{market}</StyledTheme.Title>
        </Col>
        <Col xs={12} sm={4} md={4} lg={3} className="radio-button">
          <Typography.Text>Market</Typography.Text>
          <Switch
            checkedChildren="online"
            unCheckedChildren="offline"
            onChange={(value) => onChange(value, market)}
            value={value?.status === "online" ? true : false}
            disabled={disable[market]}
            style={{
              width: "100%",
              backgroundColor: value?.status === "online" ? "green" : "red",
            }}
          />
        </Col>
        <Col className="columnTitle" xs={24}>
          MarketTrade
        </Col>
        <Col xs={12} sm={8} md={8} lg={3}>
          <ThemeTimePicker
            title="Start At"
            width={"100%"}
            inputProps={{
              name: "sessionStartTime",
              value: value?.sessionStartTime,
              onChange: handleChangeTime,
              disabled: disable[market],
            }}
          />
        </Col>
        <Col xs={12} sm={8} md={8} lg={3}>
          <ThemeTimePicker
            title="End At"
            width={"100%"}
            inputProps={{
              name: "sessionEndTime",
              value: value?.sessionEndTime,
              onChange: handleChangeTime,
              disabled: disable[market],
            }}
          />
        </Col>
        <Col className="columnTitle" xs={24}>
          Limit Trade
        </Col>
        <Col xs={12} sm={8} md={8} lg={3}>
          <ThemeTimePicker
            title="StartAfter"
            width={"100%"}
            inputProps={{
              name: "startAfter",
              value: value?.startAfter,
              onChange: handleChangeTime,
              disabled: disable[market],
              format: "mm",
            }}
          />
        </Col>
        <Col xs={12} sm={8} md={8} lg={3}>
          <ThemeTimePicker
            title="EndBefore"
            width={"100%"}
            inputProps={{
              name: "endBefore",
              value: value?.endBefore,
              onChange: handleChangeTime,
              disabled: disable[market],
              format: "mm",
            }}
          />
        </Col>
        <Col xs={12} sm={4} md={4} lg={3} className="radio-button">
          <Typography.Text>Limit</Typography.Text>
          <Switch
            title="limit"
            checkedChildren="allow"
            unCheckedChildren="block"
            onChange={(value) => onChangeLimit(value, market)}
            value={value?.isLimitTradeAllowed}
            disabled={disable[market]}
            style={{
              width: "100%",
              backgroundColor: value?.isLimitTradeAllowed ? "green" : "red",
            }}
          />
        </Col>
        <Col xs={24} sm={4} md={4} lg={3}>
          <StyleButton
            onClick={() =>
              disable[market] ? handleEdit(market) : handleSave(market)
            }
          >
            {disable[market] ? "Edit" : "Save"}
          </StyleButton>
        </Col>
      </MarketCartRow>
      {show.open && (
        <Modal
          title={"Passward Authentication"}
          open={show.open}
          onCancel={handleCancel}
          footer={false}
        >
          <UpdateMarketSetting
            value={value}
            market={show?.market}
            setShow={setShow}
            getMarketStatus={getMarketStatus}
            setDisable={setDisable}
          />
        </Modal>
      )}
    </>
  );
};

export default MarketCart;

const UpdateMarketSetting = ({
  value,
  market,
  setShow,
  getMarketStatus,
  setDisable,
}) => {
  // state
  const [loading, setLoading] = useState(false);

  // function
  const converter = (datetimeStr, minute) => {
    let date = new Date(datetimeStr);
    let timeStr = date.toTimeString().split(" ")[0];
    if (minute) {
      return timeStr.split(":")?.[1];
    } else {
      return timeStr;
    }
  };
  const updateMarketStatus = async () => {
    let newObj = {};
    let payload = value;
    for (let key in payload) {
      if (payload[key] !== "status" && payload[key] !== "isLimitTradeAllowed") {
        if (!newObj[key]) {
          const minute =
            key == "endBefore" || key == "startAfter" ? true : false;
          if (minute) {
            newObj["limitTradeAllowance"] = {
              ...newObj["limitTradeAllowance"],
              [key]: converter(payload[key], minute),
            };
          } else {
            newObj[key] = {};
            newObj[key] = converter(payload[key], minute);
          }
        }
      }
    }
    newObj.status = payload.status;
    newObj.isLimitTradeAllowed = payload.isLimitTradeAllowed;
    newObj.marketName = market;
    try {
      let response = await APICall(
        "patch",
        `${EndPoints.getMarketStatus}update-market-timing`,
        newObj
      );
      // console.log("updateMarket response", response);
      if (response.status === 200) {
        setShow({ open: false, market: null });
        notification.success({ message: response.data.message });
        getMarketStatus();
        setDisable((pre) => ({ ...pre, [market]: true }));
      } else {
        notification.error({ message: response.data.message });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const checkPassward = async (value) => {
    let obj = { password: value };
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.passwordVerification, obj);
      if (response.status === 200) {
        // console.log("res", response.data);
        updateMarketStatus();
      } else {
        setLoading(false);
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const handleFinish = (value) => {
    console.log(value, "finish");
    checkPassward(value?.passward);
  };
  const handleFinishFailed = (value) => {
    console.log(value, "failed");
  };

  return (
    <>
      <Form onFinish={handleFinish} onFinishFailed={handleFinishFailed}>
        <Row
          align={"middle"}
          justify={"space-between"}
          gutter={[10, 10]}
          style={{ marginTop: "2rem" }}
        >
          <Col xs={24} sm={18} md={16} lg={16} xl={16}>
            <ThemeInput
              title="Passward"
              name="passward"
              formProps={{
                name: "passward",
                rules: [{ required: true }],
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={6}
            md={8}
            lg={8}
            xl={8}
            style={{ textAlign: "center" }}
          >
            <Button htmlType="submit" type="primary" loading={loading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
