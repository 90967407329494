import React from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Collapse, Row, Skeleton, notification } from "antd";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import EndPoints from "../../APICall/EndPoints";
import APICall from "../../APICall";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import { useQuery } from "@tanstack/react-query";
import {
  resetPositionSocketSlice,
  setPositionConnect,
} from "../../store/slice/positionSocketSlice";
import {
  CardDetailText,
  CardTitleText,
  MarketTypeRadio,
  StyTable,
  StyleRow,
  StyledLabel,
  TitleRow,
  options1,
  options2,
  style,
} from "./style";
import {
  ScriptName,
  TotalMTM,
  TotalOutstanding,
  TotalPNL,
} from "./Components/tradePositionComponent";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import dayjs from "dayjs";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { StyledButton } from "../../styles/commonStyle";
import PositionTable from "./PositionTable";

function TradingPosition() {
  //state
  const [value, setValue] = useState({
    radioClient: 0,
    radioAll: 2,
    asOnDate: null,
  });
  const [closePositionLoading, setClosePositionLoading] = useState(false);
  const [rolloverLoading, setRolloverLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const { params, setSearchParams } = useGetQueryParams();
  const valueRef = useRef({});
  const activeRef = useRef();
  const currentPriceRef = useRef({});
  const dispatch = useDispatch();

  //redux
  const userIP = useSelector((state) => state.authSlice?.user?.userIP) || "";
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  const loginAccountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const summaryPosition = useSelector(
    (state) => state?.globleSlice?.positionFromSummay?.userId
  );
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  // api
  const getPositions = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    let payloadObj = {
      userId: Number(params?.userId),
      marketName: params?.marketName || null,
      symbolName: params?.symbolName || null,
      asOnDate: params?.asOnDate || null,
      // valan: params?.valan || null,
    };

    let response = await APICall("post", EndPoints?.tradePosition, payloadObj);
    return response?.data?.data;
  };

  const {
    isLoading,
    data,
    error: positionError,
  } = useQuery({
    queryKey: ["getPositionData", params],
    queryFn: getPositions,
    enabled: Object.keys(params)?.length > 0,
  });

  if (positionError) notification.error({ message: positionError?.message });

  // life cycle
  useEffect(() => {
    if (loginAccountType === "customer") {
      setValue((pre) => ({ ...pre, radioClient: 1 }));
    }
    setSearchParams({
      ...params,
      userId: params?.userId
        ? params.userId
        : summaryPosition
        ? summaryPosition
        : loginUserId,
    });
    if (params?.asOnDate) {
      setValue((pre) => ({ ...pre, asOnDate: dayjs(params?.asOnDate) }));
    }
    dispatch(setPositionConnect(true));

    return () => {
      dispatch(setPositionConnect(true));
      dispatch(resetPositionSocketSlice());
    };
  }, []);
  useEffect(() => {
    if (Object.keys(params)?.length == 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);
  useEffect(() => {
    if (data) {
      let filteredArray = data?.filter((el) => el?.status === "Open");
      let scriptWisedata = {};
      if (value?.radioAll == 3 && value?.radioClient == 0) {
        filteredArray?.map((el) => {
          const userID = `${el?.user?.[0]?.accountName}_${el?.user?.[0]?.userId}`;
          if (!scriptWisedata[userID]) {
            scriptWisedata[userID] = [];
            scriptWisedata[userID].push(el);
          } else {
            scriptWisedata[userID].push(el);
          }
        });
        const sorted = sortedObj(scriptWisedata, "nameWise");
        setPosition(sorted);
      } else if (value?.radioAll == 3 && value?.radioClient == 1) {
        filteredArray?.map((el) => {
          if (!scriptWisedata[el?.symbol]) {
            scriptWisedata[el?.symbol] = [];
            scriptWisedata[el?.symbol].push(el);
          } else {
            scriptWisedata[el?.symbol].push(el);
          }
        });
        const sorted = sortedObj(scriptWisedata, "symbolWise");
        setPosition(sorted);
      } else if (value?.radioAll == 2 && value?.radioClient == 0) {
        data?.map((el) => {
          const userID = `${el?.user?.[0]?.accountName}_${el?.user?.[0]?.userId}`;
          if (!scriptWisedata[userID]) {
            scriptWisedata[userID] = [];
            scriptWisedata[userID].push(el);
          } else {
            scriptWisedata[userID].push(el);
          }
        });
        const sorted = sortedObj(scriptWisedata, "nameWise");
        setPosition(sorted);
      } else {
        data?.map((el) => {
          if (!scriptWisedata[el?.symbol]) {
            scriptWisedata[el?.symbol] = [];
            scriptWisedata[el?.symbol].push(el);
          } else {
            scriptWisedata[el?.symbol].push(el);
          }
        });
        const sorted = sortedObj(scriptWisedata, "symbolWise");
        setPosition(sorted);
      }
    }
  }, [data, value?.radioAll, value?.radioClient]);

  // function
  const handleExit = () => {
    let obj = currentPriceRef.current;
    let finalPrice = [];
    let keyArray = [];
    for (let key in obj) {
      let arrayData = obj[key]?.map((el) => {
        const isOne = obj[key]?.length === 1 && position[key]?.length === 1;
        const isFull = obj[key]?.length === position[key]?.length;
        if (isOne || isFull) {
          keyArray.push(key);
        }
        return {
          id: el?.id,
          tradePrice: 0,
          High: 0,
          Low: 0,
        };
      });
      finalPrice = [...finalPrice, ...arrayData];
    }
    if (finalPrice?.length > 0) {
      let payload = {
        deviceType: "web",
        positionIds: finalPrice,
        tradeIpAddress: userIP,
        type: "CloseAll",
      };
      setClosePositionLoading(true);
      closePosition(payload, keyArray);
    } else {
      notification.error({ message: "Please select position before exit" });
    }
  };
  const handleRollover = async () => {
    let obj = currentPriceRef.current;
    let finalPrice = [];
    for (let key in obj) {
      const priceArray = obj[key]?.map((el) => {
        return { id: el?.id, tradePrice: 0 };
      });
      finalPrice = [...finalPrice, ...priceArray];
    }
    if (finalPrice?.length <= 0) {
      return notification.error({
        message: "Please select position before rollover",
      });
    }

    let payload = {
      deviceType: "web",
      positionIds: finalPrice,
      tradeIpAddress: userIP,
      type: "rollover",
    };
    setRolloverLoading(true);
    try {
      let response = await APICall("post", EndPoints.rollOverPosition, payload);
      if (response.status === 200) {
        setClearSelect(true);
        currentPriceRef.current = {};
        notification.success({ message: response?.data?.message });
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setRolloverLoading(false);
    }
  };
  const closePosition = async (obj, indexArray) => {
    try {
      let response = await APICall("post", EndPoints.closePosition, obj);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        setClearSelect(true);
        currentPriceRef.current = {};
        if (indexArray?.length > 0) {
          if (activeRef.current) {
            const collapse = activeRef.current.querySelector(".ant-collapse");
            if (collapse) {
              indexArray.forEach((el) => {
                const panelHeader = collapse.querySelector(
                  `[data-key="${el}"].ant-collapse-item-active .ant-collapse-header`
                );
                if (panelHeader) {
                  panelHeader.click();
                }
              });
            }
          }
        }
      } else {
        notification.error({ message: response?.data?.message, duration: 10 });
      }
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setClosePositionLoading(false);
    }
  };
  const handleDate = (date, string, name) => {
    setValue({ ...value, [name]: date });
  };
  const handleClear = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};
    setValue({
      radioClient: loginAccountType === "customer" ? 1 : 0,
      radioAll: 2,
      asOnDate: null,
    });
    setSearchParams({ userId: loginUserId });
  };
  const findTrade = async () => {
    let payloadValue = valueRef.current;
    let valueObj = {
      userId: payloadValue?.userId || loginUserId,
      marketName: payloadValue?.value?.marketName || null,
      symbolName: payloadValue?.value?.symbolName || null,
      asOnDate: value?.asOnDate?.toISOString() || null,
    };

    for (let key in valueObj) {
      if (!valueObj[key]) {
        delete valueObj[key];
      }
    }

    setSearchParams(valueObj);
  };
  const onChangeRadio = (e) => {
    if (activeRef.current) {
      const collapse = activeRef.current.querySelector(".ant-collapse");
      if (collapse) {
        // Find and click all expanded headers to close them
        const expandedPanels = collapse.querySelectorAll(
          ".ant-collapse-item-active .ant-collapse-header"
        );
        expandedPanels.forEach((header) => {
          header.click();
        });
      }
    }
    const value = e.target.value;
    if (value === 0 || value === 1) {
      setValue((pre) => ({ ...pre, radioClient: value }));
    } else {
      setValue((pre) => ({ ...pre, radioAll: value }));
    }
  };

  // collapse items
  const CollapseItem = Object.keys(position)?.map((el, index) => {
    return {
      key: el,
      label: (
        <StyledLabel>
          <div>
            <span>
              {value?.radioClient === 0
                ? `${position[el][0]?.user[0]?.accountName} (${position[el][0]?.user[0]?.userId}) : `
                : `${ScriptName(position[el][0])}
                     ${
                       position[el][0]?.expiryDate
                         ? valanBillDate(position[el][0]?.expiryDate, false)
                         : ""
                     } : `}
            </span>
            <TotalPNL data={position[el]} />
          </div>
          <TitleRow>
            <Col xs={24} lg={12}>
              {value?.radioClient === 1 &&
              position[el][0]?.marketName === "NSE EQU"
                ? `Market QTY: `
                : `Market QTY (LOT): `}
              <TotalOutstanding
                data={position[el]}
                value={value}
                type="market"
              />
            </Col>
            <Col xs={24} lg={12}>
              {value?.radioClient === 1 &&
              position[el][0]?.marketName === "NSE EQU"
                ? `Intraday QTY: `
                : `Intraday QTY (LOT): `}
              <TotalOutstanding
                data={position[el]}
                value={value}
                type="intraday"
              />
            </Col>
          </TitleRow>
        </StyledLabel>
      ),
      children: (
        <PositionTable
          position={position}
          closePosition={closePosition}
          currentPriceRef={currentPriceRef}
          value={value}
          el={el}
          clearSelect={clearSelect}
          setClearSelect={setClearSelect}
        />
      ),
      "data-key": el,
    };
  });

  return (
    <>
      <ThemeCard>
        <Row
          gutter={[20, 20]}
          justify={"center"}
          style={{ marginBottom: "2rem" }}
        ></Row>
        <Row
          gutter={[10, 15]}
          style={{ textAlign: "center", alignItems: "center" }}
        >
          {loginAccountType !== "customer" && (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              style={{ marginTop: "0.5rem" }}
            >
              <MarketTypeRadio
                options={options1}
                onChange={onChangeRadio}
                value={value?.radioClient}
                optionType="button"
                buttonStyle="solid"
                width={"46%"}
              />
            </Col>
          )}
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            xl={4}
            style={{ marginTop: "0.5rem" }}
          >
            <MarketTypeRadio
              options={options2}
              onChange={onChangeRadio}
              value={value.radioAll}
              optionType="button"
              buttonStyle="solid"
              width={"46%"}
            />
          </Col>
          <MarketScriptList valueRef={valueRef} />
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDatePicker
              title="As on Date"
              name="asOnDate"
              width="100%"
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
              placeholder="Select Date"
              onChange={(date, string) => handleDate(date, string, "asOnDate")}
              value={value?.asOnDate}
            />
          </Col>

          {loginAccountType !== "customer" && loginAccountType !== "broker" && (
            <UserFilterComponant valueRef={valueRef} />
          )}
        </Row>

        <Row style={{ marginTop: "3rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={findTrade}>
              Search
            </StyledButton>
          </Col>
          {loginAccountType !== "broker" && (
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <StyledButton
                onClick={handleRollover}
                type="primary"
                loading={rolloverLoading}
              >
                Rollover
              </StyledButton>
            </Col>
          )}
          {loginAccountType !== "broker" && (
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <StyledButton
                type="primary"
                onClick={handleExit}
                loading={closePositionLoading}
              >
                Exit Position
              </StyledButton>
            </Col>
          )}
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleClear}>
              Clear Filter
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCard style={{ marginTop: "2rem" }}>
        <StyleRow gutter={[10, 10]}>
          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>TOTAL MTM</CardTitleText>
            <CardDetailText>
              {data ? <TotalMTM data={data} /> : 0}
            </CardDetailText>
          </Col>

          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>BUY QTY </CardTitleText>
            <CardDetailText>
              {formatToINR(quantityCalculation(data, "buy")) || 0}
            </CardDetailText>
          </Col>
          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>SELL QTY</CardTitleText>
            <CardDetailText>
              {formatToINR(quantityCalculation(data, "sell")) || 0}
            </CardDetailText>
          </Col>
          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>TOTAL QTY</CardTitleText>
            <CardDetailText>
              {formatToINR(quantityCalculation(data, "total")) || 0}
            </CardDetailText>
          </Col>
        </StyleRow>
      </ThemeCard>

      <div style={style.tableWrraperDiv}>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {Object.keys(position)?.length == 0 ? (
              <Collapse
                style={style.collapse}
                items={[
                  {
                    key: "1",
                    label: <StyledLabel>No data found</StyledLabel>,
                  },
                ]}
              />
            ) : (
              <div ref={activeRef}>
                <Collapse items={CollapseItem} style={style.collapse} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default TradingPosition;

const quantityCalculation = (data, type) => {
  const totalBuy = data?.reduce((acc, el) => {
    return acc + (el?.totalBuyQuantity || 0);
  }, 0);

  const totalSell = data?.reduce((acc, el) => {
    return acc + el?.totalSellQuantity;
  }, 0);

  if (type === "buy") {
    return totalBuy;
  } else if (type === "sell") {
    return totalSell;
  } else {
    return totalBuy - totalSell;
  }
};

const sortedObj = (obj, type) => {
  const keys = Object.keys(obj);
  let newObj = {};
  if (type === "symbolWise") {
    keys.sort((a, b) =>
      obj[a][0].symbolName.localeCompare(obj[b][0].symbolName)
    );
    keys.forEach((el) => {
      newObj[`${obj[el][0]?.symbolName}_${el}`] = obj[el];
    });
    return newObj;
  } else {
    keys.sort((a, b) => a.localeCompare(b));
    keys.forEach((el) => {
      newObj[el] = obj[el];
    });
    return newObj;
  }
};
