// create main layout style component
import styled, { createGlobalStyle } from "styled-components";
import { MAIN_CONTENT_HEIGHT } from "./Helper/constant";

const AppStyle = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${"" /* height: 100vh; */}
  `,
  Main: styled.main`
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    ${
      "" /* height: ${(props) => (props?.$fullHeight ? "100vh" : MAIN_CONTENT_HEIGHT)};
    overflow: auto; */
    }
    background-color: rgb(240, 242, 245);
    padding-inline: 2rem;

    @media (max-width: 500px) {
      padding-inline: 1rem;
    }
  `,

  CSSReset: createGlobalStyle`
*,
*::before, 
*::after { 
     margin: 0; 
     padding: 0;
     box-sizing: inherit;
     scrollbar-width: thin;

}
        
html {
     box-sizing: border-box;  

@media (min-width: 0px) {
     font-size: 75%;    /*8px*/
}

@media (min-width: 1080px) {
    font-size: 56.25%;   /*10px*/
}

@media (min-width: 1280px) {
    font-size: 62.5%;   /*10px*/
}

@media (min-width: 1366px) {
    font-size: 68%;     /*11px*/
}

@media (min-width: 1536px) {
    font-size: 75%;    /*12px*/
}

@media (min-width: 1745px) {
    font-size: 81.2%;    /*13px*/
}

@media (min-width: 1920px) {
    font-size: 81.2%;    /*13px*/
}

@media (min-width: 2133px) {
    font-size: 87.5%;    /*14px*/
}

@media (min-width: 2400px) {
    font-size: 87.5%;    /*14px*/
}

@media (min-width: 2560px) {
    font-size: 87.5%;    /*14px*/

}    

}  
 
body {
  background-color: rgb(240, 242, 245);

iframe#webpack-dev-server-client-overlay {
  display: none;
}
    margin: 0;
    ${"" /* overflow: hidden; */}
    font-family: ${(props) => props.theme.token.fontFamily};
   
     .ant-cascader-menu-item-content {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem; /* 150% */
        display: flex;
        align-items: center;
    }
     .ant-cascader-menu {
        height: fit-content !important;
        min-width: 13rem !important;
        max-height:250px !important ;
        
    }

}
        `,
};

export default AppStyle;
