import React from "react";
import { useSelector } from "react-redux";
import ClientBrokerageView from "./ClientBrokerageView";
import MarginView from "./MarginView";
import AdditionalSharingView from "../components/AdditionalSharingView";
import ScriptWiseSettingsView from "./ScriptWiseSettingsView";
import FooterView from "../FooterView";
import { StyledText } from "../style";

const MarketAccessDetailView = React.memo(
  ({ tab, is_edits, parentForm, show, setShow, key }) => {
    //redux
    const partnerShip = useSelector(
      (state) => state.userAccountSlice?.accountDetails?.partnershipWith
    );
    const market_access = useSelector(
      (state) => state.userAccountSlice.market_access
    );
    const accountType = useSelector(
      (state) => state.userAccountSlice?.accountType
    );
    const parentScriptSetting = useSelector(
      (state) => state.authSlice?.MarketAccessDetails?.additional
    );
    const isRent = partnerShip === "rent";
    const scriptSetting = parentScriptSetting?.scriptWiseSettings;
    const additionalSetting = parentScriptSetting?.additionalSharing;
    let checked = market_access?.includes(tab) && tab;

    // function
    const onClickLink = () => {
      setShow(!show);
    };

    return (
      <div>
        {/* {checked && (
            <StyledFormItem
              label="Only position squerUp"
              name={`isOnlyPositionSquareup$${tab}`}
              defaultValue={false}
              labelCol={{ xs: 20 }}
              wrapperCol={{ xs: 4 }}
            >
              <Switch name={`isOnlyPositionSquareup$${tab}`} />
            </StyledFormItem>
          )} */}
        {checked && !isRent ? (
          <ClientBrokerageView
            key={key}
            tab={tab}
            view={"brokerageDetails"}
            is_edits={is_edits}
            form={parentForm}
          />
        ) : null}
        {checked ? (
          <MarginView
            key={key}
            tab={tab}
            view={"margin"}
            is_edits={is_edits}
            form={parentForm}
          />
        ) : null}
        <>
          {accountType === "customer" && additionalSetting !== "block" ? (
            <div className={show && checked ? "d-block" : "d-none"}>
              <AdditionalSharingView
                key={key}
                tab={tab}
                parentForm={parentForm}
                view={"additionalSharing"}
                is_edits={is_edits}
              />
            </div>
          ) : null}
          <div className={show && checked ? "d-block" : "d-none"}>
            {(scriptSetting !== "block" || accountType === "masterAdmin") && (
              <ScriptWiseSettingsView
                key={key}
                tab={tab}
                view={"scriptWiseSettingInfo"}
                is_edits={is_edits}
              />
            )}
          </div>

          {show &&
          checked &&
          (scriptSetting !== "block" || accountType === "masterAdmin") ? (
            <div>
              <FooterView
                key={key}
                tab={tab}
                view={"allScript"}
                is_edits={is_edits}
                parentForm={parentForm}
              />
            </div>
          ) : null}
        </>

        <StyledText onClick={onClickLink}>
          {(scriptSetting !== "block" || accountType === "masterAdmin") &&
          tab !== "" &&
          checked ? (
            <>
              {show
                ? "Hide Script Wise Setting (optional)"
                : "Show Script Wise Setting (optional)"}
            </>
          ) : null}
        </StyledText>
      </div>
    );
  }
);

export default MarketAccessDetailView;
