import { Form, Modal, Row, notification, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import {
  setSeletedMarket,
  setWatchListArray,
} from "../../../store/slice/marketWatchSlice";
import {
  DropdownWatchListDiv,
  StyledDropDownItem,
  marketWatchItem,
} from "../style";
import ThemeInput from "../../../components/ThemeInput";

const DropdownWatchList = ({ item, index, setDropdownOpen, dropdownOpen }) => {
  // state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // life cycle
  useEffect(() => {
    if (modal && dropdownOpen.flage) {
      setDropdownOpen({ flage: false, index: null });
    }
  }, [modal, dropdownOpen]);

  // redux
  const seletedMarket = useSelector(
    (state) => state.marketWatchSlice?.seletctedMaketIndex
  );
  const watchList = useSelector(
    (state) => state.marketWatchSlice?.watchListArray
  );

  // function
  const handleCancel = () => {
    setModal(false);
  };

  const handleOk = async (payload) => {
    setLoading(true);
    try {
      let response = await APICall(
        "post",
        `${EndPoints.editWatchList}${item?._id}`,
        payload
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        dispatch(
          setWatchListArray({
            type: "rename",
            index: index,
            name: payload.name,
          })
        );
        setModal(false);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const onClickMarketItem = async (value) => {
    if (value?.value === "rename") {
      setModal(true);
      setDropdownOpen({ flage: false, index: null });
    } else if (value?.value === "delete") {
      if (watchList.length <= 1) {
        return notification.error({
          message: "At least one watch list require on market watch",
        });
      }
      setLoading(true);
      try {
        let response = await APICall(
          "get",
          `${EndPoints.deleteWatchList}${item?._id}`
        );
        if (response.status === 200) {
          notification.success({ message: response?.data?.message });
          dispatch(
            setWatchListArray({ type: "deleteWatchList", index: index })
          );
          if (seletedMarket === index) {
            dispatch(setSeletedMarket(index == 0 ? 0 : index - 1));
          }
        } else {
          notification.error({ message: response?.data?.message });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFinish = (value) => {
    handleOk(value);
  };

  const handleFinishFailed = (value) => {
    // console.log(value, "failed");
  };

  return (
    <DropdownWatchListDiv>
      {marketWatchItem?.map((el, index) => {
        // const selected = selectedlist?.[item.value];
        return (
          <StyledDropDownItem
            // $selected={selected}
            onClick={() => onClickMarketItem(el)}
            key={el?.value}
          >
            {el?.label}
          </StyledDropDownItem>
        );
      })}
      <Form
        form={form}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Modal
          title="Rename Watch-list"
          open={modal}
          onCancel={handleCancel}
          onOk={() => form.submit()}
          confirmLoading={loading}
        >
          <Row justify={"center"} style={{ marginTop: "2rem" }}>
            <ThemeInput
              title="Rename"
              name="name"
              wrapperwidth={"90%"}
              formProps={{
                name: "name",
                initialValue: item?.name,
                rules: [
                  { required: true, message: "Please enter name" },
                  {
                    validator: (rule, value) => {
                      if (value?.length > 15) {
                        return Promise.reject(
                          "Please write name below 15 charactore"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ],
              }}
              // inputProps={{
              //   name: "rename",
              //   value: name,
              //   onChange: (e) => setName(e.target.value),
              // }}
            />
          </Row>
        </Modal>
      </Form>
    </DropdownWatchListDiv>
  );
};

export default React.memo(DropdownWatchList);
