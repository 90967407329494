import { Button, theme } from "antd";

const ActionButton = ({
  title,
  height,
  marginLeft,
  buttonType = "submit",
  ...rest
}) => {
  const token = theme.useToken().token;
  const style = {
    submitBtn: {
      borderRadius: token.borderRadiusLG,
      fontSize: token.fontSizeSM,
      height: height ? height : "3.2rem",
      backgroundColor: token.colorBgActionButton,
      boxShadow: token.boxShadowNone,
    },
    cancelBtn: {
      borderRadius: token.borderRadiusLG,
      fontSize: token.fontSizeSM,
      height: height ? height : "3.2rem",
      backgroundColor: token.marketWatchDown,
      boxShadow: token.boxShadowNone,
      marginLeft: marginLeft ? marginLeft : "2rem",
    },
  };
  return (
    <Button
      type="primary"
      style={buttonType === "submit" ? style.submitBtn : style.cancelBtn}
      {...rest}
    >
      {title}
    </Button>
  );
};
export default ActionButton;
