import React, { useEffect, useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import {
  Col,
  Collapse,
  Divider,
  Row,
  Skeleton,
  Spin,
  notification,
} from "antd";
import { useParams } from "react-router-dom";
import ThemeCard from "../../components/ThemeCard";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import {
  formatForValanBill,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { Container, SpinnerOverlay } from "../MarketSetting/MarketSetting";
import {
  StyledTheme,
  FooterRow,
  PDFButton,
  valanBillColumn,
  ScriptNameRow,
  ColorSpan,
  MarketFooterRow,
  MarketNameCol,
  HeadingRow,
  PdfRow,
  StyledTd,
  StyledTdCenter,
} from "./style";
import { sequence } from "../../Helper/constant";
import PDFDocument from "./ValanBillPDF";
import {
  formatDate,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";

const ReportCart = () => {
  // state
  const [valanBill, setValanBill] = useState({});
  const [valanData, setValanData] = useState({});
  const [valanColumn, setValanColumn] = useState([]);
  const [loading, setLoading] = useState(true);
  const { combinedParams } = useParams();

  const parseParams = (params) => {
    const [idPart, valanIdPart] = params.split("&valanId=");
    const userId = idPart;
    const valanId = valanIdPart;
    return { userId };
  };
  const { userId, valanId } = parseParams(combinedParams);

  // redux
  const loginDetails = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isCustomer = loginDetails === "customer";
  const summaryData = useSelector(
    (state) => state.globleSlice?.summaryDataFilter
  );
  const withoutBrokerage = summaryData?.withoutBrokerage;

  // column
  const reportColumn = [
    {
      title: "Sr No.",
      dataIndex: "number",
      align: "center",
      width: "2rem",
      render: (_text, record, index) => {
        if (record?.customerId) {
          return <span>{index + 1}</span>;
        }
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      align: "center",
      width: "5rem",
      render: (_text, record) => {
        if (record?.customerId) {
          return (
            <span>{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</span>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "tradeTime",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        if (record?.tradeTime) {
          let time = valanBillDate(record?.tradeTime, true);
          return <span>{time}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "title",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        return (
          <span
            style={{
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            }}
          >
            {record?.title}
          </span>
        );
      },
    },
    {
      title: "Lot",
      dataIndex: "lot",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        return <div>{record?.title !== "live" ? lot : ""}</div>;
      },
    },
    {
      title: "Buy Qty",
      dataIndex: "buyQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let buy = "-";
        if (record?.tradePattern === "buy") {
          buy = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <>
            <div
              style={{
                color: buy !== "-" ? "green" : "",
                fontWeight: "600",
              }}
            >
              {buy}
            </div>
          </>
        );
      },
    },
    {
      title: "Sell Qty",
      dataIndex: "SellQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let sell = "-";
        if (record?.tradePattern === "sell") {
          sell = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <span style={{ color: sell !== "-" ? "red" : "", fontWeight: "500" }}>
            {sell}
          </span>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "tradePrice",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.tradePrice || record?.tradePrice == 0) {
          return formatForValanBill(record?.tradePrice);
        }
      },
    },
    {
      title: "Net Rate",
      dataIndex: "netRate",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.netRate || record?.netRate == 0) {
          const netRate = formatForValanBill(record?.netRate);
          return netRate;
        }
      },
    },
    {
      title: "Net Point",
      dataIndex: "netAmount",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.netAmount || record?.netAmount == 0) {
          const netAmount = formatForValanBill(record?.netAmount);
          return netAmount;
        }
      },
    },
  ];

  // life cycle
  useEffect(() => {
    userValanBill();
  }, []);

  useEffect(() => {
    if (withoutBrokerage) {
      setValanColumn(() => {
        return reportColumn?.filter((el) => el?.dataIndex !== "netRate");
      });
    } else {
      setValanColumn(reportColumn);
    }
  }, [withoutBrokerage]);

  // api call
  const userValanBill = async () => {
    let URL = `${EndPoints.userValanBill}${userId}`;
    try {
      setLoading(true);
      let response = await APICall("post", URL, summaryData);
      if (response.status === 200) {
        const valanList = response?.data?.data;
        setValanData(valanList);
        const sortedValan = {};
        sequence.forEach((key) => {
          if (valanList.customerTrades?.[key]) {
            sortedValan[key] = valanList.customerTrades[key];
          }
        });
        setValanBill(sortedValan);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // function
  const TotalRow = ({ data, script, scriptequ }) => (
    <FooterRow>
      <td colSpan="2">ScriptWise Total :</td>
      <td></td>
      <td></td>
      {!scriptequ && <td></td>}
      <StyledTdCenter>
        {formatToINR(data?.totalBuyQuantity || 0)}
      </StyledTdCenter>
      <StyledTdCenter>
        {formatToINR(data?.totalSellQuantity || 0)}
      </StyledTdCenter>
      <StyledTd>{formatForValanBill(data?.rate)}</StyledTd>
      {!withoutBrokerage && (
        <StyledTd>{`${formatForValanBill(data?.netRate)}`}</StyledTd>
      )}
      <StyledTd> {formatForValanBill(data?.totalNetAmount)}</StyledTd>
    </FooterRow>
  );

  return (
    <Container isloading={loading.toString()}>
      {loading && (
        <SpinnerOverlay>
          <Spin size="large" />
        </SpinnerOverlay>
      )}

      <PdfRow align={"bottom"} justify={"space-between"}>
        <StyledTheme.ThemeColumn>
          {`Live Point: ${
            valanData?.user?.accountName ? valanData?.user?.accountName : ""
          }(${valanData?.user?.userId ? valanData?.user?.userId : ""}) ` || 0}
        </StyledTheme.ThemeColumn>
        <StyledTheme.ThemeColumn>
          {`Valan: ${
            valanData?.valan?.valanName ? valanData?.valan?.valanName : ""
          } ` || 0}
        </StyledTheme.ThemeColumn>
        <StyledTheme.ThemeColumn>
          {`From: ${
            valanData?.valan?.startDate
              ? formatDate(valanData?.valan?.startDate)
              : ""
          } To ${
            valanData?.valan?.endDate
              ? formatDate(valanData?.valan?.endDate)
              : ""
          } ` || 0}
        </StyledTheme.ThemeColumn>
        {valanData?.user?.accountName && (
          <StyledTheme.ThemeColumn>
            {/* <LazyPDFDownload
              valanBill={valanBill}
              valanData={valanData}
              withoutBrokerage={withoutBrokerage}
              valanBillColumn={valanBillColumn}
              isCustomer={isCustomer}
            /> */}
            <PDFDownloadButton
              valanBill={valanBill}
              valanData={valanData}
              withoutBrokerage={withoutBrokerage}
              valanBillColumn={
                withoutBrokerage
                  ? valanBillColumn?.filter((el) => el?.dataIndex !== "netRate")
                  : valanBillColumn
              }
              isCustomer={isCustomer}
            />
          </StyledTheme.ThemeColumn>
        )}
      </PdfRow>

      {valanData?.user?.accountType !== "customer" && (
        <Row justify={"space-between"} style={{ fontWeight: "500" }}>
          <StyledTheme.ThemeBuyColumn>
            {`Buy Turnover Point: ${
              formatToINR(valanData?.totalBuyTurnover || 0) || 0
            }`}
          </StyledTheme.ThemeBuyColumn>
          <StyledTheme.ThemeSellColumn>
            {`Sell Turnover Point: ${
              formatToINR(valanData?.totalSellTurnover || 0) || 0
            }`}
          </StyledTheme.ThemeSellColumn>
        </Row>
      )}
      {valanData?.user?.accountType === "customer" && (
        <Divider style={{ margin: "0px" }} />
      )}
      <HeadingRow
        justify={isCustomer ? "center" : "space-between"}
        loading={loading}
      >
        {!isCustomer && (
          <StyledTheme.HeaderColumn>{`Without Brokerage Point: ${formatToINR(
            valanData?.totalGroce || 0
          )}`}</StyledTheme.HeaderColumn>
        )}
        {!withoutBrokerage && !isCustomer && (
          <StyledTheme.HeaderColumn>{`Total Brokerage: ${formatToINR(
            valanData?.totalBrokerage || 0
          )}`}</StyledTheme.HeaderColumn>
        )}
        {!withoutBrokerage && valanData?.user?.accountType !== "customer" && (
          <StyledTheme.HeaderColumn>
            {`Self Brokerage: ${formatToINR(valanData?.selfBrokerage || 0)}`}
          </StyledTheme.HeaderColumn>
        )}
        {valanData?.user?.accountType !== "customer" && (
          <StyledTheme.HeaderColumn>
            {`Self Point: `}
            <ColorSpan
              color={
                valanData?.selfAmount
                  ? valanData?.selfAmount > 0
                    ? "green"
                    : "red"
                  : "black"
              }
            >
              {formatToINR(valanData?.selfAmount || 0)}
            </ColorSpan>
          </StyledTheme.HeaderColumn>
        )}

        <StyledTheme.HeaderColumn>
          {`Final Point With Brokerage: `}
          <ColorSpan
            color={
              valanData?.totalNetAmount
                ? valanData?.totalNetAmount > 0
                  ? "green"
                  : "red"
                : "black"
            }
          >
            {formatToINR(valanData?.totalNetAmount || 0)}
          </ColorSpan>
        </StyledTheme.HeaderColumn>
      </HeadingRow>

      {Object?.keys?.(valanBill)?.length === 0 && (
        <Row justify={"center"} style={{ fontSize: "4rem", marginTop: "4rem" }}>
          <StyledTheme.Table
            columns={valanBillColumn}
            className="w-100"
            dataSource={[]}
            pagination={false}
            locale={{
              emptyText: loading ? <Skeleton active /> : null,
            }}
            bordered={false}
          />
        </Row>
      )}
      {Object?.keys?.(valanBill)?.length > 0 &&
        Object?.keys?.(valanBill)?.map((script, index) => {
          let scriptequ = script === "NSE EQU" ? true : false;
          return (
            <ThemeCard style={{ marginBottom: "2rem" }} key={index}>
              <StyledTheme.TitleRow justify={"space-between"} align="bottom">
                <MarketNameCol xs={24} sm={12}>
                  {script}
                </MarketNameCol>

                <Col xs={24} sm={12} className="segement-bill-title">
                  {`Segment Point :`}
                  <ColorSpan
                    color={
                      valanBill?.[script]?.totalMarketNetAmount
                        ? valanBill?.[script]?.totalMarketNetAmount > 0
                          ? "green"
                          : "red"
                        : "black"
                    }
                  >
                    {formatToINR(
                      valanBill?.[script]?.totalMarketNetAmount || 0
                    )}
                  </ColorSpan>
                </Col>
              </StyledTheme.TitleRow>
              {/* <Collapse items={createCollapseItem(script)} /> */}
              {valanBill?.[script]?.trades?.map((item, index) => {
                const date = item?.trades?.[0]?.expiryDate;
                const finalDate = valanBillDate(date, false);
                const opt = item?._id?.marketName?.endsWith("OPT");
                const name = opt
                  ? `${item?._id?.symbolName} ${
                      item?._id?.strikePrice ? item?._id?.strikePrice : ""
                    } ${item?._id?.series ? item?._id?.series : ""} `
                  : item?._id?.symbolName;
                return (
                  <div key={index}>
                    <ScriptNameRow justify={"center"}>
                      {name} {finalDate ? finalDate : null}
                    </ScriptNameRow>

                    <StyledTheme.Root style={{ marginBottom: "1rem" }}>
                      <StyledTheme.Table
                        columns={
                          !scriptequ
                            ? valanColumn
                            : valanColumn?.filter(
                                (el) => el?.dataIndex !== "lot"
                              )
                        }
                        dataSource={item?.trades}
                        pagination={false}
                        bordered={true}
                        isLoading={loading ? <Skeleton /> : false}
                        rowKey="_id"
                        scroll={{
                          x: 1250,
                        }}
                        style={{
                          position: "sticky",
                        }}
                        summary={() => (
                          <TotalRow
                            data={item}
                            script={script}
                            scriptequ={scriptequ}
                          />
                        )}
                      />
                    </StyledTheme.Root>
                  </div>
                );
              })}
              {!withoutBrokerage && (
                <MarketFooterRow justify={"space-evenly"}>
                  {!isCustomer && (
                    <StyledTheme.ThemeColumn>
                      {`Total Brokerage: ${formatToINR(
                        valanBill?.[script]?.totalBrokerage || 0
                      )}`}
                    </StyledTheme.ThemeColumn>
                  )}
                  {valanData?.user?.accountType !== "customer" && (
                    <StyledTheme.ThemeColumn>
                      {`Self Brokerage: ${formatToINR(
                        valanBill?.[script]?.selfBrokerage || 0
                      )}`}
                    </StyledTheme.ThemeColumn>
                  )}
                </MarketFooterRow>
              )}
            </ThemeCard>
          );
        })}
    </Container>
  );
};

export default ReportCart;

const PDFDownloadButton = ({
  valanBill,
  valanData,
  withoutBrokerage,
  valanBillColumn,
  isCustomer,
}) => {
  const [isGenerating, setIsGenerating] = useState({
    flage: false,
    index: null,
  });

  const generateAndDownloadPdf = async (value) => {
    try {
      setIsGenerating({ flage: true, index: value ? 0 : 1 });
      // Generate the PDF document
      const PDFDoc = (
        <PDFDocument
          valanBill={valanBill}
          grossRef={valanData}
          withoutBrokerage={
            value === "withoutBrokerage" ? true : withoutBrokerage
          }
          column={valanBillColumn}
          isCustomer={isCustomer}
          withNetRate={value === "withoutBrokerage" ? true : false}
        />
      );

      // Create blob
      const blob = await pdf(PDFDoc).toBlob();

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${valanData?.user?.accountName}(${valanData?.user?.userId}) Valan Point.pdf`;

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsGenerating({ flage: false, index: null });
    }
  };

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      {valanData?.user?.accountType === "customer" && !withoutBrokerage && (
        <PDFButton
          onClick={() => generateAndDownloadPdf("withoutBrokerage")}
          loading={isGenerating?.flage && isGenerating?.index === 0}
          style={{ width: "100%" }}
        >
          {isGenerating?.flage && isGenerating?.index === 0
            ? "Generating PDF..."
            : "Download PDF Without Brokerage"}
        </PDFButton>
      )}
      <PDFButton
        onClick={() => generateAndDownloadPdf()}
        loading={isGenerating?.flage && isGenerating?.index === 1}
      >
        {isGenerating?.flage && isGenerating?.index === 1
          ? "Generating PDF..."
          : "Download PDF"}
      </PDFButton>
    </div>
  );
};

const LazyPDFDownload = ({
  valanBill,
  valanData,
  withoutBrokerage,
  valanBillColumn,
  isCustomer,
}) => {
  const [showPDF, setShowPDF] = useState(false);

  if (!showPDF) {
    return (
      <PDFButton onClick={() => setShowPDF(true)} style={{ color: "white" }}>
        Generate & Download PDF
      </PDFButton>
    );
  }

  return (
    <PDFDownloadLink
      document={
        <PDFDocument
          valanBill={valanBill}
          grossRef={valanData}
          withoutBrokerage={withoutBrokerage}
          column={valanBillColumn}
          isCustomer={isCustomer}
        />
      }
      fileName={`${valanData?.user?.accountName}(${valanData?.user?.userId})Valan Point.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <PDFButton loading={loading} style={{ color: "white" }}>
          {loading ? "Generating PDF..." : "Download PDF"}
        </PDFButton>
      )}
    </PDFDownloadLink>
  );
};

const createCollapseItem = (script) => {
  // return valanBill?.[script]?.trades?.map((item, index) => {
  //   const date = item?.trades?.[0]?.expiryDate;
  //   const finalDate = valanBillDate(date, false);
  //   const opt = item?._id?.marketName?.endsWith("OPT");
  //   const scriptequ = false;
  //   const script = item?._id?.symbolName;
  //   const name = opt
  //     ? `${item?._id?.symbolName} ${
  //         item?._id?.strikePrice ? item?._id?.strikePrice : ""
  //       } ${item?._id?.series ? item?._id?.series : ""} `
  //     : item?._id?.symbolName;
  //   return {
  //     key: index,
  //     label: name,
  //     children: (
  //       <div key={index}>
  //         <ScriptNameRow justify={"center"}>
  //           {name} {finalDate ? finalDate : null}
  //         </ScriptNameRow>
  //         <StyledTheme.Root style={{ marginBottom: "1rem" }}>
  //           <StyledTheme.Table
  //             // virtual
  //             columns={scriptequ ? reportColNseEqu : reportColumn}
  //             dataSource={item?.trades}
  //             pagination={false}
  //             bordered={true}
  //             isLoading={loading ? <Skeleton /> : false}
  //             rowKey="_id"
  //             scroll={{
  //               x: 1250,
  //             }}
  //             style={{
  //               position: "sticky",
  //             }}
  //             summary={() => (
  //               <TotalRow data={item} script={script} scriptequ={scriptequ} />
  //             )}
  //           />
  //         </StyledTheme.Root>
  //       </div>
  //     ),
  //   };
  // });
};
