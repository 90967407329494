import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setExprirySetting } from "../../store/slice/expirySettingSlice";
import {
  ExpirySettingRow,
  ExpirySquareUpRow,
  Label,
  NewPositionRow,
} from "./style";
import { SEGMENTS, optionDays, options } from "../../Helper/constant";
import ThemeDropDown from "../../components/ThemeDropDown";
import ThemeCard from "../../components/ThemeCard";
import { useGetScript } from "../../APICall/apiHooks";

const canGetScript = (tab) => {
  if (tab === "COMEX" || tab === "MCX" || tab === "MCX OPT") {
    return true;
  } else {
    return false;
  }
};
const NseOptionRender = ({ tab }) => {
  // custom hook for get script list
  const { scriptList, scriptLoading } = useGetScript({
    marketName: canGetScript(tab) ? SEGMENTS[tab] : null,
  });
  const dispatch = useDispatch();

  // redux
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    switch (name) {
      case "squareUpRate":
        dispatch(
          setExprirySetting({
            key: "squareUpRate",
            value: selectedValue[0],
            tab: tab,
            parent_key: "intraday",
          })
        );
        break;
      case "rate":
      case "dayBefore":
        dispatch(
          setExprirySetting({
            key: [name],
            value: selectedValue[0],
            tab: tab,
            parent_key: "position",
            nested_key: "expirySquareUpRateAndDayBefore",
          })
        );
        break;
      case "valanEndingSquareUpRate":
      case "newPositionStartBeforeDay":
        dispatch(
          setExprirySetting({
            key: [name],
            value: selectedValue[0],
            tab: tab,
            parent_key: "position",
          })
        );
        break;
      case "scriptName":
        dispatch(
          setExprirySetting({
            key: [name],
            value: selectedValue[0],
            tab: tab,
          })
        );
        break;
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            title="Script Name"
            placeholder="Select"
            name="scriptName"
            width={"98%"}
            showSearch={true}
            options={
              tab !== "NSE OPT" ? scriptList : options.user.Expiry.NSE_OPTIONS
            }
            $onChange={handleChange}
            $value={expiryValue?.scriptName}
            suffixIcon={<Spin size="small" spinning={scriptLoading} />}
          />
        </Col>
      </Row>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{ marginTop: "1rem" }}
      >
        <ExpirySettingRow>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.squareUpRate}
            />
          </Col>
        </ExpirySettingRow>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{ marginTop: "2rem" }}
      >
        <ExpirySquareUpRow gutter={[10, 10]}>
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.position?.valanEndingSquareUpRate}
            />
          </Col>
        </ExpirySquareUpRow>
        <NewPositionRow>
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue.position?.newPositionStartBeforeDay}
            />
          </Col>
        </NewPositionRow>
      </ThemeCard>
    </>
  );
};

export default NseOptionRender;
