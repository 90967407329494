import { theme, Avatar, Dropdown, Tag, Col, Badge, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  LogoutOutlined,
  ReloadOutlined,
  ProfileOutlined,
  BellOutlined,
  NotificationOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { HEADER_HEIGHT } from "../../Helper/constant";
import { setAuth } from "../../store/slice/authSlice";
import NiftySenView from "../NiftySenView/index";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { clearLocalStorage } from "../../store/store";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import {
  StyleHeader,
  DigitalClockContainer,
  ClockBorder,
  FlipDigitContainer,
  TopDigit,
  BottomDigit,
  CurrentDigit,
  Colon,
  ActiveTotalUser,
  ActiveText,
  ActiveNumber,
  LiveUserDivider,
} from "./index.style";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const isMobile = breakpoint.xs || (breakpoint.sm && !breakpoint.md);

  //state
  const [showVideo, setShowVideo] = useState(false);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const Usertoken = useSelector((state) => state.authSlice?.accessToken);
  const socketflag = useSelector(
    (state) => state?.webSocketSlice?.webSocket?.socket
  );

  //items
  const items = [
    {
      key: "avatar",
      label: (
        <>
          Welcome {userDetail?.accountName} ({userDetail?.userId}){" "}
          <Tag
            color="magenta"
            bordered={false}
            style={{
              fontSize: "1rem",
              marginLeft: "0.5rem",
            }}
          >
            {userDetail?.accountType}
          </Tag>
        </>
      ),
      icon: <UserOutlined />,
    },
    userDetail?.accountType !== "owner" &&
      userDetail?.accountType !== "broker" && {
        key: "profile",
        label: <span>Profile</span>,
        icon: <ProfileOutlined />,
      },
    {
      key: "resetPassword",
      label: <span>Reset Password</span>,
      icon: <ReloadOutlined />,
    },
    {
      key: "RulesAndRegulation",
      label: <span>Rules And Regulation</span>,
      icon: <ExclamationCircleOutlined />,
    },
    {
      key: "logout",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
    },
  ];

  //style
  const token = theme.useToken().token;
  const style = {
    header: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: token.colorTextLightSolid,
      height: HEADER_HEIGHT,
      backgroundColor: token.colorPrimary1,
      fontFamily: token.fontFamily,
      paddingInline: "5rem",
      lineHeight: "normal",
    },
    coinsText: {
      margin: 0,
      marginLeft: "0.5rem",
      color: token.colorTextLightSolid,
      display: "flex",
      alignItems: "center",
      fontSize: token.fontSizeHeading5,
    },
    icon_wrapper: {
      display: "flex",
      alignItems: "center",
    },
    iconWrapper: {
      display: "flex",
      width: "3rem",
      height: "3rem",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "6.25rem",
      background: token.colorPrimaryLight,
      marginLeft: "1rem",
    },
    notification_icon: {
      width: "1.5rem",
      height: "1.5rem",
    },
  };

  //api call
  const logout = async () => {
    try {
      let response = await APICall("post", EndPoints.userLogout);
      console.log("response Logout", response);
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(setAuth(null));
      localStorage.removeItem("token");
      clearLocalStorage();
      setTimeout(() => {
        localStorage.removeItem("persist:root");
      }, 1000);
      navigate("/login");
    }
  };

  //functions
  const handleMenuClick = (value) => {
    if (value?.key === "logout") {
      logout();
    } else if (value?.key === "resetPassword") {
      navigate("/resetPassward");
    } else if (value?.key === "profile") {
      navigate("/profile");
    } else if (value?.key === "RulesAndRegulation") {
      navigate("/rules-and-regulation");
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <StyleHeader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col style={{ alignItems: "start" }}>
          <NiftySenView />
        </Col>
      </div>

      <div style={style.icon_wrapper}>
        {/* <div style={{ position: "relative", marginRight: "1rem" }}>
          <Tooltip title={"CNBC LIVE"}>
            <PlaySquareOutlined
              style={{ fontSize: "24px", color: "white", cursor: "pointer" }}
              onClick={() => setShowVideo(true)}
            />
          </Tooltip>
          {showVideo && (
            <Draggable
              position={dragPosition}
              onDrag={(e, data) => setDragPosition({ x: data.x, y: data.y })}
              bounds="body"
            >
              <div
                style={{
                  position: "fixed",
                  zIndex: 1000,
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  background: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "move",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    marginBottom: "5px",
                    background: "#e0e0e0",
                    textAlign: "center",
                    borderRadius: "4px",
                    display: "flex",
                    color: "black",

                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Text style={{ fontWeight: "600" }}>
                    CNBC LIVE
                  </Typography.Text>

                  <CloseOutlined
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowVideo(false)}
                  />
                </div>

                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${"Uyr9dCg0JyQ"}?autoplay=1&rel=0`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Draggable>
          )}
        </div> */}

        {/* <LiveUserDivider type="vertical" />
        <TotalActiveUser /> */}
        <DigitalClock />

        {/* <div style={{ ...style.iconWrapper }}>
          <Notification />
        </div> */}

        <div style={style.iconWrapper}>
          <Dropdown
            menu={menuProps}
            placement="bottomLeft"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <Avatar size={"small"} icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </div>
    </StyleHeader>
  );
};

export default Header;

const TotalActiveUser = () => {
  const total = useSelector((state) => state?.webSocketSlice?.totalLiveUser);

  return (
    <ActiveTotalUser>
      <ActiveText>{`Active Users`}</ActiveText>
      <ActiveNumber>{total ? total : 0}</ActiveNumber>
    </ActiveTotalUser>
  );
};

const DigitalClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  return (
    <DigitalClockContainer>
      <ClockBorder>
        <FlipDigit digit={hours[0]} />
        <FlipDigit digit={hours[1]} />
        <Colon>:</Colon>
        <FlipDigit digit={minutes[0]} />
        <FlipDigit digit={minutes[1]} />
        <Colon>:</Colon>
        <FlipDigit digit={seconds[0]} />
        <FlipDigit digit={seconds[1]} />
      </ClockBorder>
    </DigitalClockContainer>
  );
};

const FlipDigit = ({ digit }) => {
  const [flip, setFlip] = useState(false);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setFlip(true);
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [digit]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setFlip(false);
  //   }, 1000);

  //   return () => clearTimeout(timeout);
  // }, [digit, flip]);

  return (
    <FlipDigitContainer>
      {/* <TopDigit flip={flip.toString()}>{digit}</TopDigit> */}
      <CurrentDigit>{digit}</CurrentDigit>
      {/* <BottomDigit flip={flip.toString()}>{digit}</BottomDigit> */}
    </FlipDigitContainer>
  );
};
const Notification = () => {
  // state
  const [m2mMessage, setm2mMessage] = useState([]);
  const [m2mCount, setM2mCount] = useState(0);

  // redux
  const m2mAlert = useSelector((state) => state?.globleSlice?.m2mAlert);

  // life cycle
  useEffect(() => {
    if (m2mAlert) {
      const updatedM2m = m2mAlert?.map((el, index) => {
        return {
          ...el,
          key: index,
          label: <span>{`${el?.message}(${el?.userId}-${el?.userName})`}</span>,
          icon: <NotificationOutlined />,
        };
      });
      setm2mMessage(updatedM2m);
      setM2mCount(updatedM2m.length);
    }
  }, [m2mAlert]);

  // function
  const notificationProps = {
    items: m2mMessage,
  };

  const handleDropdownVisibleChange = (visible) => {
    if (!visible) {
      setM2mCount(0);
    }
  };

  return (
    <Dropdown
      menu={notificationProps}
      placement="bottomLeft"
      arrow={{
        pointAtCenter: true,
      }}
      trigger={["click"]}
      onOpenChange={handleDropdownVisibleChange}
      // onClick={handleDropdown}
    >
      <div>
        <Badge count={m2mCount > 0 ? m2mCount : ""} size="small">
          <BellOutlined style={{ fontSize: "1.4rem", color: "white" }} />
        </Badge>
      </div>
    </Dropdown>
  );
};
