import { Button, Row, Typography } from "antd";
import styled from "styled-components";

export const StyleButton = styled(Button)`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: white !important;
  width: 100%;

  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
`;
export const StyledTheme = {
  Title: styled(Typography.Title)`
    &.ant-typography {
      width: ${(props) => props.$width};
      /* color: ${(props) => props.theme.token.colorTextLabel}; */
      font-family: ${(props) => props.theme.token.fontFamily};
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0rem;
      line-height: 1.125rem;
      text-align: left;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
  `,
};
export const markets = [
  { label: "NSE FUT", value: "nsefut" },
  { label: "NSE OPT", value: "nseopt" },
  { label: "NSE EQU", value: "nseequ" },
  { label: "MCX", value: "mcx" },
  { label: "MCX OPT", value: "mcxopt" },
  { label: "COMEX", value: "comex" },
  { label: "FOREX", value: "forex" },
  { label: "GLOBEX", value: "globex" },
  // { label: "CRYPTO", value: "crypto" },
];
export const StyleDiv = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  width: 80%;

  @media (max-width: 1080px) {
    width: 100%;
  } */
  display: grid;
  white-space: nowrap;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const MarketCartRow = styled(Row)`
  margin: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
`;
export const MarketSettingRow = styled(Row)`
  margin-bottom: 1rem;
  font-weight: 500;
  display: flex;
  gap: 17rem;
`;
