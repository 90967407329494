import { Button, Col, Form, Modal, Row, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import ThemeInput from "../ThemeInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { ScriptWiseSettingCheckbox } from "../../pages/Users/NewAccount/style";
import { AccountTypeRadioButton } from "../../pages/Users/Accounts/style";
import { useSelector } from "react-redux";
import { GlobleMarket } from "../../Helper/constant";
import { useCheckPassward } from "../../APICall/apiHooks";

const VerificationModal = React.memo(
  ({
    handleCancel,
    record,
    handleExecute,
    executeLoading,
    type,
    title,
    customTitle,
    customeDescription,
    query,
    customPrice,
  }) => {
    //state
    const [limitType, setLimitType] = useState("market");
    const [price, setPrice] = useState("");
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [isIncognito, setIsIncognito] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // custom hook for passward verification
    const { flage, passwardLoding, passwardMutate, setFlage } =
      useCheckPassward();
    const { params, setSearchParams } = useGetQueryParams();

    //redux
    const isOwner =
      useSelector((state) => state?.authSlice?.userDetail?.accountType) ===
      "owner";
    const currentPrice = record?.tradePattern === "sell" ? "Ask" : "Bid";
    const liveRate =
      useSelector(
        (state) =>
          state?.webSocketSlice?.webSocket?.data?.[record?.symbol]?.[
            currentPrice
          ]
      ) || 0;

    const {
      isLoading: deleteLoading,
      error,
      mutate,
    } = useMutation({
      mutationFn: async (payload) => {
        let response = await APICall(
          "delete",
          `${EndPoints.deleteTrade}${payload?.id}`,
          payload
        );

        return response;
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          notification.success({ message: res?.data?.message });
          if (query === "tradingTrade") {
            queryClient.refetchQueries({ queryKey: [query, params] });
          } else {
            queryClient.refetchQueries({ queryKey: [query] });
          }
          handleCancel();
        } else {
          notification.error({ message: res?.data?.message });
        }
      },
    });

    if (error) notification.error({ message: error?.message });

    // life cycle
    useEffect(() => {
      // Start loading when either password verification or execution starts
      const isLoadingActive =
        passwardLoding || executeLoading || deleteLoading || flage;

      setIsLoading(isLoadingActive);
    }, [passwardLoding, executeLoading, deleteLoading, flage]);

    useEffect(() => {
      if (flage) {
        if (type === "execute") {
          const payloadPrice =
            limitType === "market"
              ? liveRate
              : limitType === "limit"
              ? record?.tradePrice
              : price;
          handleExecute(record, limitType, payloadPrice);
        } else {
          let payload = {
            deviceType: "web",
            tradeIpAddress: record?.tradeIpAddress,
            id: record?._id,
          };
          if (isOwner) payload.incognito = isIncognito;
          mutate(payload);
        }
        setFlage(false);
      }
    }, [flage]);

    // functions
    const handleFinishFailed = (value) => {
      console.log(value, "failed");
    };

    const handleFinish = (value) => {
      passwardMutate(value.passward);
    };

    const handleSubmit = () => {
      form.submit();
    };

    const onChangeRadio = (e) => {
      setLimitType(e.target.value);
    };
    const handlePriceChange = (e) => {
      const value = e.target.value;
      const validMarket = GlobleMarket.includes(record?.marketName);
      if (validMarket) {
        setPrice(value);
        return;
      }
      const validFormat = /^\d*\.?\d{0,2}$/.test(value);
      const isEmptyOrValid = validFormat || value === "";
      if (
        record?.marketName === "MCX" &&
        parseFloat(value) > 1500 &&
        isEmptyOrValid
      ) {
        setPrice(parseFloat(value));
        return;
      }
      if (isEmptyOrValid) {
        const decimal = value.split(".")[1]?.toString();
        const isNSEMarket = ["NSE EQU", "NSE FUT"].includes(record?.marketName);
        const isValidDecimal =
          !decimal ||
          decimal.length === 1 ||
          (decimal.length === 2 &&
            (decimal[1] === "0" || decimal[1] === "5" || isNSEMarket));

        if (isValidDecimal) {
          setPrice(value);
        }
      }
    };

    return (
      <Modal
        title={
          <div style={{ display: "flex", gap: "1rem" }}>
            <ExclamationCircleTwoTone style={{ fontSize: "larger" }} />
            <Typography.Title level={4} style={{ marginBottom: "0px" }}>
              {customTitle
                ? customTitle
                : `${title} - ${
                    query === "rejectionLogs"
                      ? `${record?.client?.userId} (${record?.client?.accountName})`
                      : `${record?.customer?.[0]?.userId} (${record?.customer?.[0]?.accountName})`
                  } `}
            </Typography.Title>
          </div>
        }
        open={true}
        onCancel={handleCancel}
        onOk={handleSubmit}
        confirmLoading={isLoading}
      >
        <Typography.Title level={5} type="secondary">
          {customeDescription
            ? customeDescription
            : `Are you sure to ${type} ${record?.tradePattern} ${record?.symbolName} ${record?.quantity} (${record?.lot}) @ ${record?.tradePrice} ?`}
        </Typography.Title>
        {isOwner && type !== "execute" && (
          <Col style={{ marginTop: "1rem" }}>
            <ScriptWiseSettingCheckbox
              name={`Incognito`}
              checked={isIncognito}
              onChange={(e) => setIsIncognito(e.target.checked)}
            >
              Incognito
            </ScriptWiseSettingCheckbox>
          </Col>
        )}
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          {customPrice && isOwner && (
            <Row
              align={"middle"}
              justify={"space-between"}
              style={{ marginTop: "2rem" }}
              gutter={[10, 15]}
            >
              <Col span={24}>
                <AccountTypeRadioButton
                  options={ExecuteOption}
                  onChange={onChangeRadio}
                  value={limitType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
              <Col span={24}>
                <ThemeInput
                  title="Price"
                  inputProps={{
                    readOnly: limitType !== "custom" ? true : false,
                    onChange: handlePriceChange,
                    value:
                      limitType == "market"
                        ? liveRate
                        : limitType === "limit"
                        ? record?.tradePrice
                        : price,
                  }}
                  formProps={{
                    ...(limitType === "custom"
                      ? {
                          name: "Price",
                          rules: [
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject("Price is required");
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ],
                        }
                      : {}),
                  }}
                />
              </Col>
            </Row>
          )}
          <Row
            align={"middle"}
            justify={"space-between"}
            gutter={[10, 10]}
            style={{ marginTop: customPrice ? "0rem" : "2rem" }}
          >
            <Col span={24}>
              <ThemeInput
                title="Enter Your Passward"
                name="passward"
                formProps={{
                  name: "passward",
                  rules: [{ required: true }],
                }}
                wrapperProps={{ style: { width: "90%" } }}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default VerificationModal;

const ExecuteOption = [
  {
    label: "Market",
    value: "market",
  },
  {
    label: "Limit",
    value: "limit",
  },
  {
    label: "Custom",
    value: "custom",
  },
];
